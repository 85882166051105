import { useState, useEffect } from "react";
import { Box, Typography, Button, Skeleton, CardMedia, CircularProgress } from "@mui/material";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TopMerchants from "./TopMerchants";
import * as React from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { useAppDispatch, useAppSelector } from "../../../../state/hooks";
import { GetPaymentAnalystics } from "../../../../state/feautures/finance/getPaymentAnalysticsSlice";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { GetTopTenTransaction } from "../../../../state/feautures/finance/getTopTenTransactionSlice";
import { GetAccountBalance } from "../../../../state/feautures/finance/getAccountBalanceSlice";
import { GetTransactionCount } from "../../../../state/feautures/finance/getTransactionCountSlice";
import { GetTransactionValue } from "../../../../state/feautures/finance/getTransactionValueSlice";
import {
  AccountBalanceItem,
  TransactionCountItem,
  TransactionValueItem,
} from "../../../../types/FinanceData";
import { InactiveIcon } from "../../../../common/assets/icons";

interface TransactionData {
  typeTransaction: string;
  nbre?: number;
  amount?: number;
}
interface Credentials {
  startDate: string;
  endDate: string;
  operator: string;
}

function mergeData(
  data1: TransactionData[],
  data2: TransactionData[]
): TransactionData[] {
  const mergedMap = new Map<string, TransactionData>();

  // Merge data from first array
  data1.forEach((item) => {
    const { typeTransaction, nbre } = item;
    let existingItem = mergedMap.get(typeTransaction);
    if (!existingItem) {
      mergedMap.set(typeTransaction, {
        typeTransaction,
        amount: 0,
        nbre: nbre || 0,
      });
    } else {
      existingItem.nbre = (existingItem.nbre || 0) + (nbre || 0);
    }
  });

  // Merge data from second array
  data2.forEach((item) => {
    const { typeTransaction, amount } = item;
    let existingItem = mergedMap.get(typeTransaction);
    if (!existingItem) {
      mergedMap.set(typeTransaction, {
        typeTransaction,
        nbre: 0,
        amount: amount || 0,
      });
    } else {
      existingItem.amount = (existingItem.amount || 0) + (amount || 0);
    }
  });

  // Convert map values to array of objects
  const mergedData = Array.from(mergedMap.values());

  return mergedData;
}

interface AnalyticsData {
  count: number;
  value: number;
  percentage: number;
}

interface AnalyticsProps {
  analyticsData: {
    failed: Record<string, AnalyticsData>;
    successful: Record<string, AnalyticsData>;
  };
}
const AnalyticsDisplay: React.FC<AnalyticsProps> = ({ analyticsData }) => {
  const renderData = (
    data: Record<string, AnalyticsData>,
    status: "successful" | "failed"
  ) => (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "20px",
      }}
    >
      <Typography
        sx={{
          color: status === "successful" ? "#797D8C" : "#FF5403",
          border: `1px solid ${
            status === "successful" ? "#1FAF38" : "#FF5403"
          }`,
          background: `rgba(${
            status === "successful" ? "31, 175, 56" : "255, 84, 3"
          }, 0.1)`,
          height: "20px",
          borderRadius: "8px",
          fontSize: "14px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {status.charAt(0).toUpperCase() + status.slice(1)}
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "15px",
          justifyContent: "space-between",
        }}
      >
        {Object.keys(data).map((key) => (
          <Box
            key={key}
            sx={{
              display: "flex",
              // flexDirection: "column",
              gap: "15px",
            }}
          >
            <Typography sx={{ fontSize: "14px", color: "#797D8C" }}>
              {key.charAt(0).toUpperCase() + key.slice(1)}
            </Typography>
            <Typography sx={{ fontSize: "14px", color: "#797D8C" }}>
              {data[key].count}
            </Typography>
            <Typography sx={{ fontSize: "14px", color: "#797D8C" }}>
              {data[key].value.toLocaleString()} XOF
            </Typography>
            <Typography sx={{ fontSize: "14px", color: "#797D8C" }}>
              {data[key].percentage}%
            </Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );

  return (
    <Box sx={{ display: "flex" }}>
      {renderData(analyticsData.successful, "successful")}
      {renderData(analyticsData.failed, "failed")}
    </Box>
  );
};
// Example usage
// const data1: TransactionData[] = [
//   {
//     typeTransaction: "REQUESTPAYMENT",
//     nbre: 84914,
//   },
//   {
//     typeTransaction: "REQUESTPAYMENTMV",
//     nbre: 11266,
//   },
//   {
//     typeTransaction: "PAYOUTMV",
//     nbre: 29,
//   },
//   {
//     typeTransaction: "PAYOUT",
//     nbre: 189,
//   },
//   {
//     typeTransaction: "VERIFYPAYMENT",
//     nbre: 138,
//   },
//   {
//     typeTransaction: "MAKEDEPOSITMV",
//     nbre: 2213,
//   },
//   {
//     typeTransaction: "MAKEDEPOSIT",
//     nbre: 18205,
//   },
//   {
//     typeTransaction: "TOPUPCARD",
//     nbre: 10,
//   },
//   {
//     typeTransaction: "REFUND",
//     nbre: 13,
//   },
//   {
//     typeTransaction: "REQUESTPAYMENT",
//     nbre: 27,
//   },
//   {
//     typeTransaction: "REQUESTPAYMENT",
//     nbre: 6,
//   },
// ];

// const data2: TransactionData[] = [
//   {
//     typeTransaction: "REQUESTPAYMENT",
//     amount: 6.99258598123e11,
//   },
//   {
//     typeTransaction: "REQUESTPAYMENTMV",
//     amount: 1.8982341e8,
//   },
//   {
//     typeTransaction: "PAYOUTMV",
//     amount: 3014057.0,
//   },
//   {
//     typeTransaction: "PAYOUT",
//     amount: 1.45453438e10,
//   },
//   {
//     typeTransaction: "VERIFYPAYMENT",
//     amount: 138.0,
//   },
//   {
//     typeTransaction: "MAKEDEPOSITMV",
//     amount: 5.1584038e7,
//   },
//   {
//     typeTransaction: "MAKEDEPOSIT",
//     amount: 6.4914198438e10,
//   },
//   {
//     typeTransaction: "TOPUPCARD",
//     amount: 3.2101909e7,
//   },
//   {
//     typeTransaction: "REFUND",
//     amount: 51400.0,
//   },
//   {
//     typeTransaction: "REQUESTPAYMENT",
//     amount: 54230.0,
//   },
//   {
//     typeTransaction: "REQUESTPAYMENT",
//     amount: 16350.0,
//   },
// ];

// const TransCard = ({ title, amount, color }: any) => {
//   return (
//     <Box
//       sx={{
//         width: "100%",
//         backgroundColor: "#FFFFFF",
//         border: "solid 1px #E0E0E0",
//         borderRadius: "8px",
//         padding: "12px",
//         display: "flex",
//         flexDirection: "column",
//         alignItems: "flex-start",
//         gap: "05px",
//       }}
//     >
//       <Box
//         sx={{
//           display: "flex",
//           alignItems: "center",
//           gap: "8px",
//         }}
//       >
//         {color && (
//           <Box
//             sx={{
//               width: "12px",
//               height: "12px",
//               backgroundColor: color,
//               borderRadius: "50%",
//             }}
//           ></Box>
//         )}
//         <Typography sx={{ color: "#797D8C", fontSize: "13px" }}>
//           {title}
//         </Typography>
//       </Box>
//       <Box sx={{ fontSize: "23px", fontWeight: "700" }}>{amount}</Box>
//     </Box>
//   );
// };

function TabPanel(props: any) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const FinanceOverview = () => {
  // const today = new Date();
  // const TopTenMerchantsResponse = useAppSelector(
  //   (state) => state.getTopTenTransaction
  // );
  // const AccountBalanceResponse: any = useAppSelector(
  //   (state) => state.accountBalance
  // );
  // const TransactionCountResponse: any = useAppSelector(
  //   (state) => state.accountBalance
  // );
  // const TransactionValueResponse: any = useAppSelector(
  //   (state) => state.accountBalance
  // );
  // const AccountBalanceData = AccountBalanceResponse.data;
  // const TopTenMerchantsData = TopTenMerchantsResponse.data;
  // const TransactionCountData = TransactionCountResponse.data;
  // const TransactionValuedata = TransactionValueResponse.data;
  // console.log("TransactionCountData", TransactionCountData);
  // console.log("TransactionValuedata", TransactionValuedata);
  const dispatch = useAppDispatch();
  const PaymentAnalystics = useAppSelector((state) => state.paymentanalystics);

  const PaymentAnalysticsLoading = PaymentAnalystics.loading;
  const PaymentAnalysticsTopMerchants =
    PaymentAnalystics?.data?.datas?.topMerchants;
  const analyticsData = PaymentAnalystics?.data?.datas?.analyticsData;

  const [value, setValue] = useState(0);
  // const [dateDeb, setDateDeb] = useState(today);
  // const [dateFin, setDateFin] = useState(today);
  const [operator, setOperator] = React.useState("MTN");
  const [customDate, setCustomDate] = useState(false);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const formatDate = (date: Date | null) => {
    return date ? dayjs(date).format("YYYY-MM-DD") : "";
  };
  const handleStartDateChange = (date: Date | null) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date: Date | null) => {
    setEndDate(date);
  };
  // const [filteredData, setFilteredData] = useState(AccountBalanceData);

  // const handleOperatorChange = (event: SelectChangeEvent) => {
  //   setOperator(event.target.value);
  // };
  // Function to filter the array based on selected operator

  // useEffect(() => {
  //   const filterResults = (operator: any) => {
  //     const filteredData = operator
  //       ? AccountBalanceData.filter((item: any) => item.operator === operator)
  //       : AccountBalanceData;
  //     setFilteredData(filteredData);
  //   };
  //   filterResults(operator);
  // }, [operator, AccountBalanceData]);
  useEffect(() => {
    const credential = {
      operator: operator,
      startDate: startDate,
      endDate: endDate,
    };
    dispatch(GetPaymentAnalystics(credential));
  }, [dispatch]);
  useEffect(() => {
    const getDateRangeForValue = (
      value: number
    ): { start: string; end: string } => {
      const today = new Date();
      const formatDate = (date: Date): string => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const day = String(date.getDate()).padStart(2, "0");
        return `${year}-${month}-${day}`;
      };

      // Initialize startDate and endDate
      let startDate: Date;
      let endDate: Date;

      switch (value) {
        case 0:
          startDate = today;
          endDate = today;
          break;
        case 1:
          const dayOfWeek = today.getDay();
          const startOfWeek = 0; // Sunday
          const endOfWeek = 6; // Saturday

          startDate = new Date(today);
          startDate.setDate(today.getDate() - dayOfWeek + startOfWeek);

          endDate = new Date(today);
          endDate.setDate(today.getDate() + (endOfWeek - dayOfWeek));
          break;
        case 2:
          startDate = new Date(today.getFullYear(), today.getMonth(), 1);
          endDate = new Date(today.getFullYear(), today.getMonth() + 1, 0);
          break;
        case 3:
          const quarter = Math.floor(today.getMonth() / 3);
          switch (quarter) {
            case 0:
              startDate = new Date(today.getFullYear(), 0, 1); // Q1 start
              endDate = new Date(today.getFullYear(), 2, 31); // Q1 end
              break;
            case 1:
              startDate = new Date(today.getFullYear(), 3, 1); // Q2 start
              endDate = new Date(today.getFullYear(), 5, 30); // Q2 end
              break;
            case 2:
              startDate = new Date(today.getFullYear(), 6, 1); // Q3 start
              endDate = new Date(today.getFullYear(), 8, 30); // Q3 end
              break;
            case 3:
              startDate = new Date(today.getFullYear(), 9, 1); // Q4 start
              endDate = new Date(today.getFullYear(), 11, 31); // Q4 end
              break;
            default:
              throw new Error("Invalid Quartar");
          }
          break;
        case 4:
          startDate = new Date("1970-01-01");
          endDate = today;
          break;
        default:
          startDate = new Date("");
          endDate = new Date("");
      }
      return {
        start: formatDate(startDate),
        end: formatDate(endDate),
      };
    };
    const dateRange = getDateRangeForValue(value);
    const requestCredentials: Credentials = {
      startDate: dateRange.start,
      endDate: dateRange.end,
      operator: operator,
    };
    if (value !== 5) {
      dispatch(GetPaymentAnalystics(requestCredentials));
    }
  }, [dispatch, value, operator]);

  const handleCustomDate = () => {
    setCustomDate(!customDate);
    const requestCredentials: Credentials = {
      startDate: formatDate(startDate),
      endDate: formatDate(endDate),
      operator: operator,
    };
    dispatch(GetPaymentAnalystics(requestCredentials));
  };
  // useEffect(() => {
  //   const credential = {
  //     dateDeb: dateDeb,
  //     dateFin: dateFin,
  //   };
  //   dispatch(GetTopTenTransaction());
  //   dispatch(GetAccountBalance(credential));
  //   dispatch(GetTransactionCount(credential));
  //   dispatch(GetTransactionValue(credential));
  // }, [dispatch, dateDeb, dateFin]);

  const handleChange = (event: any, newValue: any) => {
    setValue(newValue);
  };

  // function formatDate(inputDate: any) {
  //   const formattedDate = inputDate.toISOString().split("T")[0];
  //   return formattedDate;
  // }

  // const handleSetTodayDate = () => {
  //   const formattedToday = formatDate(today);
  //   setDateDeb(formattedToday);
  //   setDateFin(formattedToday);
  //   console.log(formattedToday);
  // };
  // useEffect(() => {
  //   handleSetTodayDate();
  // });

  // const handleSetYesterdayDate = () => {
  //   const yesterday = new Date(today);
  //   yesterday.setDate(today.getDate() - 1);
  //   const formattedYesterday = formatDate(yesterday);
  //   setDateDeb(formattedYesterday);
  //   setDateFin(formattedYesterday);
  //   console.log(formattedYesterday);
  // };
  const handleOperatorChange = (event: SelectChangeEvent) => {
    setOperator(event.target.value);
  };
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
      {/* Heading */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "start",
            alignItems: "start",
            marginBottom: "20px",
          }}
        >
          <Typography
            sx={{ fontSize: "24px", color: "#45464E", marginBottom: "-5px" }}
          >
            Payment Overview
          </Typography>
          <Typography
            sx={{
              fontSize: "16px",
              color: "#B2B2B2",
              fontWeight: 500,
              marginTop: "0",
            }}
          >
            Manage your Payment
          </Typography>
        </Box>
        <FormControl sx={{ marginLeft: "0", width: "200px" }}>
          <InputLabel id="demo-select-small-label">Operator</InputLabel>
          <Select
            labelId="demo-select-small-label"
            id="demo-select-small"
            value={operator}
            label="Operator"
            onChange={handleOperatorChange}
          >
            <MenuItem value="MTN">MTN</MenuItem>
            <MenuItem value="MOOV">MOOV</MenuItem>
            <MenuItem value="MOOVTG">MOOVTG</MenuItem>
            <MenuItem value="TOGOCEL">TOGOCEL</MenuItem>
            <MenuItem value="HUBCI">HUBCI</MenuItem>
          </Select>
        </FormControl>
      </Box>
      <Typography
        sx={{
          fontSize: "16px",
          color: "#45464E",
          fontWeight: 700,
          marginTop: "0",
          textAlign: "left",
        }}
      >
        {value === 0
          ? "Today"
          : value === 1
          ? "This Week"
          : value === 2
          ? "This Month"
          : value === 3
          ? "This Quarter"
          : value === 4
          ? "All Time"
          : "Custom"}
      </Typography>
      {/* Tabs Head*/}
      <Box sx={{ borderBottom: 0, marginBottom: "15px", marginTop: "15px" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          sx={{
            borderBottom: "none", // Remove the default bottom border
          }}
        >
          <Tab
            label="Today"
            className={value === 0 ? "activeTab" : ""}
            sx={{
              border: value === 0 ? "#F59E0B 1px solid" : "#EFF1F6  1px solid",
              borderRadius: "8px",
              fontSize: "14px",
              lineHeight: "16px",
              textTransform: "capitalize",
              marginRight: "12px",
              backgroundColor: value === 0 ? "#FCDFAE" : "inherit",
            }}
            {...a11yProps(0)}
          />
          <Tab
            label="This Week"
            className={value === 1 ? "activeTab" : ""}
            sx={{
              border: value === 1 ? "#F59E0B 1px solid" : "#EFF1F6  1px solid",
              borderRadius: "8px",
              fontSize: "14px",
              lineHeight: "16px",
              textTransform: "capitalize",
              marginRight: "12px",
              backgroundColor: value === 1 ? "#FCDFAE" : "inherit",
            }}
            {...a11yProps(1)}
          />
          <Tab
            label="This Month"
            className={value === 2 ? "activeTab" : ""}
            sx={{
              border: value === 2 ? "#F59E0B 1px solid" : "#EFF1F6  1px solid",
              borderRadius: "8px",
              fontSize: "14px",
              lineHeight: "16px",
              textTransform: "capitalize",
              marginRight: "12px",
              backgroundColor: value === 2 ? "#FCDFAE" : "inherit",
            }}
            {...a11yProps(2)}
          />
          <Tab
            label="This Quarter"
            className={value === 3 ? "activeTab" : ""}
            sx={{
              border: value === 3 ? "#F59E0B 1px solid" : "#EFF1F6  1px solid",
              borderRadius: "8px",
              fontSize: "14px",
              lineHeight: "16px",
              textTransform: "capitalize",
              marginRight: "12px",
              backgroundColor: value === 3 ? "#FCDFAE" : "inherit",
            }}
            {...a11yProps(3)}
          />
          <Tab
            label="All Time"
            className={value === 4 ? "activeTab" : ""}
            sx={{
              border: value === 4 ? "#F59E0B 1px solid" : "#EFF1F6  1px solid",
              borderRadius: "8px",
              fontSize: "14px",
              lineHeight: "16px",
              textTransform: "capitalize",
              marginRight: "12px",
              backgroundColor: value === 4 ? "#FCDFAE" : "inherit",
            }}
            {...a11yProps(4)}
          />
          <Tab
            label="Custom Date"
            className={value === 5 ? "activeTab" : ""}
            sx={{
              border: value === 5 ? "#F59E0B 1px solid" : "#EFF1F6  1px solid",
              borderRadius: "8px",
              fontSize: "14px",
              lineHeight: "16px",
              textTransform: "capitalize",
              marginRight: "12px",
              backgroundColor: value === 5 ? "#FCDFAE" : "inherit",
            }}
            {...a11yProps(5)}
          />
        </Tabs>
      </Box>

      {/* Tabs Body */}
      <Box>
        <Box sx={{ marginBottom: "30px" }}>
          <TabPanel value={value} index={0}> 
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "2fr 1fr",
                gap: "30px",
              }}
            >
              <Box
                sx={{
                  background: "#FFFFFF",
                  padding: "14px 25px",
                  display: "flex",
                  flexDirection: "column",
                  gap: "30px",
                }}
              >
                <Typography
                  sx={{ fontSize: "14px", color: "#797D8C", textAlign: "left" }}
                >
                  Payments by transaction type:
                </Typography>

                {PaymentAnalysticsLoading ? (
                  <Box> <CircularProgress /> </Box>
                ) : analyticsData === undefined ? (
                  <Typography>No Data</Typography>
                ) : (
                  <Box
                    sx={{
                      display: "grid",
                      gridTemplateColumns: "0.5fr 1fr 1fr",
                      gap: "30px",
                      textAlign: "left",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "12px",
                      }}
                    >
                      <Typography sx={{ fontSize: "16px", color: "#FFF" }}>
                        .
                      </Typography>
                      <Typography sx={{ fontSize: "16px", color: "#FFF" }}>
                        .
                      </Typography>
                      {Object.keys(analyticsData.successful).map((key) => (
                        <Box
                          key={key}
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            gap: "15px",
                          }}
                        >
                          <Typography
                            sx={{ fontSize: "14px", color: "#797D8C" }}
                          >
                            {
                              analyticsData.successful[
                                key as keyof typeof analyticsData.successful
                              ].count
                            }
                          </Typography>
                        </Box>
                      ))}
                      {/* <Typography sx={{ fontSize: "16px", color: "#56616B" }}>
                      Collection
                    </Typography>
                    <Typography sx={{ fontSize: "16px", color: "#56616B" }}>
                      Disbursement
                    </Typography>
                    <Typography sx={{ fontSize: "16px", color: "#56616B" }}>
                      Refund
                    </Typography>
                    <Typography sx={{ fontSize: "16px", color: "#56616B" }}>
                      Payout
                    </Typography>
                    <Typography sx={{ fontSize: "16px", color: "#56616B" }}>
                      Transfer
                    </Typography>
                    <Typography sx={{ fontSize: "16px", color: "#56616B" }}>
                      Top up
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "16px",
                        color: "#56616B",
                        fontWeight: "700",
                      }}
                    >
                      All
                    </Typography> */}
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "20px",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#797D8C",
                          border: "1px solid #1FAF38",
                          background: "rgba(31, 175, 56, 0.1)",
                          height: "20px",
                          borderRadius: "8px",
                          fontSize: "14px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        Succesful
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          gap: "15px",
                          justifyContent: "space-between",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "12px",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              gap: "15px",
                            }}
                          >
                            {" "}
                            <Typography
                              sx={{ fontSize: "14px", color: "#797D8C" }}
                            >
                              Count
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              gap: "15px",
                            }}
                          >
                            {Object.keys(analyticsData.successful).map(
                              (key) => (
                                <Box
                                  key={key}
                                  sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: "15px",
                                  }}
                                >
                                  <Typography
                                    sx={{ fontSize: "14px", color: "#797D8C" }}
                                  >
                                    {
                                      analyticsData.successful[
                                        key as keyof typeof analyticsData.successful
                                      ].count
                                    }
                                  </Typography>
                                </Box>
                              )
                            )}
                            <Typography
                              sx={{
                                fontSize: "14px",
                                color: "#797D8C",
                                fontWeight: "700",
                              }}
                            ></Typography>
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "12px",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              gap: "15px",
                            }}
                          >
                            {" "}
                            <Typography
                              sx={{ fontSize: "14px", color: "#797D8C" }}
                            >
                              Value
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              gap: "15px",
                            }}
                          >
                            {Object.keys(analyticsData.successful).map(
                              (key) => (
                                <Box
                                  key={key}
                                  sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: "15px",
                                  }}
                                >
                                  <Typography
                                    sx={{ fontSize: "14px", color: "#797D8C" }}
                                  >
                                    {
                                      analyticsData.successful[
                                        key as keyof typeof analyticsData.successful
                                      ].value
                                    }
                                  </Typography>
                                </Box>
                              )
                            )}
                            <Typography
                              sx={{
                                fontSize: "14px",
                                color: "#797D8C",
                                fontWeight: "700",
                              }}
                            ></Typography>
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "12px",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              gap: "15px",
                            }}
                          >
                            <Typography
                              sx={{ fontSize: "14px", color: "#797D8C" }}
                            >
                              %
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              gap: "15px",
                            }}
                          >
                            {Object.keys(analyticsData.successful).map(
                              (key) => (
                                <Box
                                  key={key}
                                  sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: "15px",
                                  }}
                                >
                                  <Typography
                                    sx={{ fontSize: "14px", color: "#797D8C" }}
                                  >
                                    {
                                      analyticsData.successful[
                                        key as keyof typeof analyticsData.successful
                                      ].percentage
                                    }
                                    %
                                  </Typography>
                                </Box>
                              )
                            )}
                            <Typography
                              sx={{
                                fontSize: "14px",
                                color: "#797D8C",
                                fontWeight: "700",
                              }}
                            ></Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "20px",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#797D8C",
                          border: "1px solid #FF5403",
                          background: "rgba(255, 84, 3, 0.1)",
                          height: "20px",
                          borderRadius: "8px",
                          fontSize: "14px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        Failed
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          gap: "15px",
                          justifyContent: "space-between",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "12px",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              gap: "15px",
                            }}
                          >
                            {" "}
                            <Typography
                              sx={{ fontSize: "14px", color: "#797D8C" }}
                            >
                              Count
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              gap: "15px",
                            }}
                          >
                            {Object.keys(analyticsData.successful).map(
                              (key) => (
                                <Box
                                  key={key}
                                  sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: "15px",
                                  }}
                                >
                                  <Typography
                                    sx={{ fontSize: "14px", color: "#797D8C" }}
                                  >
                                    {
                                      analyticsData.successful[
                                        key as keyof typeof analyticsData.failed
                                      ].count
                                    }
                                  </Typography>
                                </Box>
                              )
                            )}
                            <Typography
                              sx={{
                                fontSize: "14px",
                                color: "#797D8C",
                                fontWeight: "700",
                              }}
                            ></Typography>
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "12px",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              gap: "15px",
                            }}
                          >
                            {" "}
                            <Typography
                              sx={{ fontSize: "14px", color: "#797D8C" }}
                            >
                              Value
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              gap: "15px",
                            }}
                          >
                            {Object.keys(analyticsData.successful).map(
                              (key) => (
                                <Box
                                  key={key}
                                  sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: "15px",
                                  }}
                                >
                                  <Typography
                                    sx={{ fontSize: "14px", color: "#797D8C" }}
                                  >
                                    {
                                      analyticsData.successful[
                                        key as keyof typeof analyticsData.failed
                                      ].value
                                    }
                                  </Typography>
                                </Box>
                              )
                            )}
                            <Typography
                              sx={{
                                fontSize: "14px",
                                color: "#797D8C",
                                fontWeight: "700",
                              }}
                            ></Typography>
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "12px",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              gap: "15px",
                            }}
                          >
                            <Typography
                              sx={{ fontSize: "14px", color: "#797D8C" }}
                            >
                              %
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              gap: "15px",
                            }}
                          >
                            {Object.keys(analyticsData.successful).map(
                              (key) => (
                                <Box
                                  key={key}
                                  sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: "15px",
                                  }}
                                >
                                  <Typography
                                    sx={{ fontSize: "14px", color: "#797D8C" }}
                                  >
                                    {
                                      analyticsData.successful[
                                        key as keyof typeof analyticsData.failed
                                      ].percentage
                                    }
                                    %
                                  </Typography>
                                </Box>
                              )
                            )}
                            <Typography
                              sx={{
                                fontSize: "14px",
                                color: "#797D8C",
                                fontWeight: "700",
                              }}
                            ></Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                )}
              </Box>
        
              <Box></Box>
            </Box>
          </TabPanel>
          <TabPanel value={value} index={1}>
          <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "2fr 1fr",
                gap: "30px",
              }}
            >
              <Box
                sx={{
                  background: "#FFFFFF",
                  padding: "14px 25px",
                  display: "flex",
                  flexDirection: "column",
                  gap: "30px",
                }}
              >
                <Typography
                  sx={{ fontSize: "14px", color: "#797D8C", textAlign: "left" }}
                >
                  Payments by transaction type:
                </Typography>

                {PaymentAnalysticsLoading ? (
                <Box> <CircularProgress /> </Box>
                ) : analyticsData === undefined ? (
                  <Typography>No Data</Typography>
                ) : (
                  <Box
                    sx={{
                      display: "grid",
                      gridTemplateColumns: "0.5fr 1fr 1fr",
                      gap: "30px",
                      textAlign: "left",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "12px",
                      }}
                    >
                      <Typography sx={{ fontSize: "16px", color: "#FFF" }}>
                        .
                      </Typography>
                      <Typography sx={{ fontSize: "16px", color: "#FFF" }}>
                        .
                      </Typography>
                      {Object.keys(analyticsData.successful).map((key) => (
                        <Box
                          key={key}
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            gap: "15px",
                          }}
                        >
                          <Typography
                            sx={{ fontSize: "14px", color: "#797D8C" }}
                          >
                            {
                              analyticsData.successful[
                                key as keyof typeof analyticsData.successful
                              ].count
                            }
                          </Typography>
                        </Box>
                      ))}
                      {/* <Typography sx={{ fontSize: "16px", color: "#56616B" }}>
                      Collection
                    </Typography>
                    <Typography sx={{ fontSize: "16px", color: "#56616B" }}>
                      Disbursement
                    </Typography>
                    <Typography sx={{ fontSize: "16px", color: "#56616B" }}>
                      Refund
                    </Typography>
                    <Typography sx={{ fontSize: "16px", color: "#56616B" }}>
                      Payout
                    </Typography>
                    <Typography sx={{ fontSize: "16px", color: "#56616B" }}>
                      Transfer
                    </Typography>
                    <Typography sx={{ fontSize: "16px", color: "#56616B" }}>
                      Top up
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "16px",
                        color: "#56616B",
                        fontWeight: "700",
                      }}
                    >
                      All
                    </Typography> */}
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "20px",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#797D8C",
                          border: "1px solid #1FAF38",
                          background: "rgba(31, 175, 56, 0.1)",
                          height: "20px",
                          borderRadius: "8px",
                          fontSize: "14px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        Succesful
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          gap: "15px",
                          justifyContent: "space-between",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "12px",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              gap: "15px",
                            }}
                          >
                            {" "}
                            <Typography
                              sx={{ fontSize: "14px", color: "#797D8C" }}
                            >
                              Count
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              gap: "15px",
                            }}
                          >
                            {Object.keys(analyticsData.successful).map(
                              (key) => (
                                <Box
                                  key={key}
                                  sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: "15px",
                                  }}
                                >
                                  <Typography
                                    sx={{ fontSize: "14px", color: "#797D8C" }}
                                  >
                                    {
                                      analyticsData.successful[
                                        key as keyof typeof analyticsData.successful
                                      ].count
                                    }
                                  </Typography>
                                </Box>
                              )
                            )}
                            <Typography
                              sx={{
                                fontSize: "14px",
                                color: "#797D8C",
                                fontWeight: "700",
                              }}
                            ></Typography>
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "12px",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              gap: "15px",
                            }}
                          >
                            {" "}
                            <Typography
                              sx={{ fontSize: "14px", color: "#797D8C" }}
                            >
                              Value
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              gap: "15px",
                            }}
                          >
                            {Object.keys(analyticsData.successful).map(
                              (key) => (
                                <Box
                                  key={key}
                                  sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: "15px",
                                  }}
                                >
                                  <Typography
                                    sx={{ fontSize: "14px", color: "#797D8C" }}
                                  >
                                    {
                                      analyticsData.successful[
                                        key as keyof typeof analyticsData.successful
                                      ].value
                                    }
                                  </Typography>
                                </Box>
                              )
                            )}
                            <Typography
                              sx={{
                                fontSize: "14px",
                                color: "#797D8C",
                                fontWeight: "700",
                              }}
                            ></Typography>
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "12px",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              gap: "15px",
                            }}
                          >
                            <Typography
                              sx={{ fontSize: "14px", color: "#797D8C" }}
                            >
                              %
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              gap: "15px",
                            }}
                          >
                            {Object.keys(analyticsData.successful).map(
                              (key) => (
                                <Box
                                  key={key}
                                  sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: "15px",
                                  }}
                                >
                                  <Typography
                                    sx={{ fontSize: "14px", color: "#797D8C" }}
                                  >
                                    {
                                      analyticsData.successful[
                                        key as keyof typeof analyticsData.successful
                                      ].percentage
                                    }
                                    %
                                  </Typography>
                                </Box>
                              )
                            )}
                            <Typography
                              sx={{
                                fontSize: "14px",
                                color: "#797D8C",
                                fontWeight: "700",
                              }}
                            ></Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "20px",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#797D8C",
                          border: "1px solid #FF5403",
                          background: "rgba(255, 84, 3, 0.1)",
                          height: "20px",
                          borderRadius: "8px",
                          fontSize: "14px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        Failed
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          gap: "15px",
                          justifyContent: "space-between",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "12px",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              gap: "15px",
                            }}
                          >
                            {" "}
                            <Typography
                              sx={{ fontSize: "14px", color: "#797D8C" }}
                            >
                              Count
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              gap: "15px",
                            }}
                          >
                            {Object.keys(analyticsData.successful).map(
                              (key) => (
                                <Box
                                  key={key}
                                  sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: "15px",
                                  }}
                                >
                                  <Typography
                                    sx={{ fontSize: "14px", color: "#797D8C" }}
                                  >
                                    {
                                      analyticsData.successful[
                                        key as keyof typeof analyticsData.failed
                                      ].count
                                    }
                                  </Typography>
                                </Box>
                              )
                            )}
                            <Typography
                              sx={{
                                fontSize: "14px",
                                color: "#797D8C",
                                fontWeight: "700",
                              }}
                            ></Typography>
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "12px",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              gap: "15px",
                            }}
                          >
                            {" "}
                            <Typography
                              sx={{ fontSize: "14px", color: "#797D8C" }}
                            >
                              Value
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              gap: "15px",
                            }}
                          >
                            {Object.keys(analyticsData.successful).map(
                              (key) => (
                                <Box
                                  key={key}
                                  sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: "15px",
                                  }}
                                >
                                  <Typography
                                    sx={{ fontSize: "14px", color: "#797D8C" }}
                                  >
                                    {
                                      analyticsData.successful[
                                        key as keyof typeof analyticsData.failed
                                      ].value
                                    }
                                  </Typography>
                                </Box>
                              )
                            )}
                            <Typography
                              sx={{
                                fontSize: "14px",
                                color: "#797D8C",
                                fontWeight: "700",
                              }}
                            ></Typography>
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "12px",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              gap: "15px",
                            }}
                          >
                            <Typography
                              sx={{ fontSize: "14px", color: "#797D8C" }}
                            >
                              %
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              gap: "15px",
                            }}
                          >
                            {Object.keys(analyticsData.successful).map(
                              (key) => (
                                <Box
                                  key={key}
                                  sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: "15px",
                                  }}
                                >
                                  <Typography
                                    sx={{ fontSize: "14px", color: "#797D8C" }}
                                  >
                                    {
                                      analyticsData.successful[
                                        key as keyof typeof analyticsData.failed
                                      ].percentage
                                    }
                                    %
                                  </Typography>
                                </Box>
                              )
                            )}
                            <Typography
                              sx={{
                                fontSize: "14px",
                                color: "#797D8C",
                                fontWeight: "700",
                              }}
                            ></Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                )}
              </Box>
   
              <Box></Box>
            </Box>
          </TabPanel>
          <TabPanel value={value} index={2}>
          <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "2fr 1fr",
                gap: "30px",
              }}
            >
              <Box
                sx={{
                  background: "#FFFFFF",
                  padding: "14px 25px",
                  display: "flex",
                  flexDirection: "column",
                  gap: "30px",
                }}
              >
                <Typography
                  sx={{ fontSize: "14px", color: "#797D8C", textAlign: "left" }}
                >
                  Payments by transaction type:
                </Typography>

                {PaymentAnalysticsLoading ? (
                <Box> <CircularProgress /> </Box>
                ) : analyticsData === undefined ? (
                  <Typography>No Data</Typography>
                ) : (
                  <Box
                    sx={{
                      display: "grid",
                      gridTemplateColumns: "0.5fr 1fr 1fr",
                      gap: "30px",
                      textAlign: "left",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "12px",
                      }}
                    >
                      <Typography sx={{ fontSize: "16px", color: "#FFF" }}>
                        .
                      </Typography>
                      <Typography sx={{ fontSize: "16px", color: "#FFF" }}>
                        .
                      </Typography>
                      {Object.keys(analyticsData.successful).map((key) => (
                        <Box
                          key={key}
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            gap: "15px",
                          }}
                        >
                          <Typography
                            sx={{ fontSize: "14px", color: "#797D8C" }}
                          >
                            {
                              analyticsData.successful[
                                key as keyof typeof analyticsData.successful
                              ].count
                            }
                          </Typography>
                        </Box>
                      ))}
                      {/* <Typography sx={{ fontSize: "16px", color: "#56616B" }}>
                      Collection
                    </Typography>
                    <Typography sx={{ fontSize: "16px", color: "#56616B" }}>
                      Disbursement
                    </Typography>
                    <Typography sx={{ fontSize: "16px", color: "#56616B" }}>
                      Refund
                    </Typography>
                    <Typography sx={{ fontSize: "16px", color: "#56616B" }}>
                      Payout
                    </Typography>
                    <Typography sx={{ fontSize: "16px", color: "#56616B" }}>
                      Transfer
                    </Typography>
                    <Typography sx={{ fontSize: "16px", color: "#56616B" }}>
                      Top up
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "16px",
                        color: "#56616B",
                        fontWeight: "700",
                      }}
                    >
                      All
                    </Typography> */}
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "20px",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#797D8C",
                          border: "1px solid #1FAF38",
                          background: "rgba(31, 175, 56, 0.1)",
                          height: "20px",
                          borderRadius: "8px",
                          fontSize: "14px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        Succesful
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          gap: "15px",
                          justifyContent: "space-between",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "12px",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              gap: "15px",
                            }}
                          >
                            {" "}
                            <Typography
                              sx={{ fontSize: "14px", color: "#797D8C" }}
                            >
                              Count
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              gap: "15px",
                            }}
                          >
                            {Object.keys(analyticsData.successful).map(
                              (key) => (
                                <Box
                                  key={key}
                                  sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: "15px",
                                  }}
                                >
                                  <Typography
                                    sx={{ fontSize: "14px", color: "#797D8C" }}
                                  >
                                    {
                                      analyticsData.successful[
                                        key as keyof typeof analyticsData.successful
                                      ].count
                                    }
                                  </Typography>
                                </Box>
                              )
                            )}
                            <Typography
                              sx={{
                                fontSize: "14px",
                                color: "#797D8C",
                                fontWeight: "700",
                              }}
                            ></Typography>
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "12px",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              gap: "15px",
                            }}
                          >
                            {" "}
                            <Typography
                              sx={{ fontSize: "14px", color: "#797D8C" }}
                            >
                              Value
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              gap: "15px",
                            }}
                          >
                            {Object.keys(analyticsData.successful).map(
                              (key) => (
                                <Box
                                  key={key}
                                  sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: "15px",
                                  }}
                                >
                                  <Typography
                                    sx={{ fontSize: "14px", color: "#797D8C" }}
                                  >
                                    {
                                      analyticsData.successful[
                                        key as keyof typeof analyticsData.successful
                                      ].value
                                    }
                                  </Typography>
                                </Box>
                              )
                            )}
                            <Typography
                              sx={{
                                fontSize: "14px",
                                color: "#797D8C",
                                fontWeight: "700",
                              }}
                            ></Typography>
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "12px",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              gap: "15px",
                            }}
                          >
                            <Typography
                              sx={{ fontSize: "14px", color: "#797D8C" }}
                            >
                              %
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              gap: "15px",
                            }}
                          >
                            {Object.keys(analyticsData.successful).map(
                              (key) => (
                                <Box
                                  key={key}
                                  sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: "15px",
                                  }}
                                >
                                  <Typography
                                    sx={{ fontSize: "14px", color: "#797D8C" }}
                                  >
                                    {
                                      analyticsData.successful[
                                        key as keyof typeof analyticsData.successful
                                      ].percentage
                                    }
                                    %
                                  </Typography>
                                </Box>
                              )
                            )}
                            <Typography
                              sx={{
                                fontSize: "14px",
                                color: "#797D8C",
                                fontWeight: "700",
                              }}
                            ></Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "20px",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#797D8C",
                          border: "1px solid #FF5403",
                          background: "rgba(255, 84, 3, 0.1)",
                          height: "20px",
                          borderRadius: "8px",
                          fontSize: "14px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        Failed
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          gap: "15px",
                          justifyContent: "space-between",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "12px",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              gap: "15px",
                            }}
                          >
                            {" "}
                            <Typography
                              sx={{ fontSize: "14px", color: "#797D8C" }}
                            >
                              Count
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              gap: "15px",
                            }}
                          >
                            {Object.keys(analyticsData.failed).map(
                              (key) => (
                                <Box
                                  key={key}
                                  sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: "15px",
                                  }}
                                >
                                  <Typography
                                    sx={{ fontSize: "14px", color: "#797D8C" }}
                                  >
                                    {
                                      analyticsData.failed[
                                        key as keyof typeof analyticsData.failed
                                      ].count
                                    }
                                  </Typography>
                                </Box>
                              )
                            )}
                            <Typography
                              sx={{
                                fontSize: "14px",
                                color: "#797D8C",
                                fontWeight: "700",
                              }}
                            ></Typography>
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "12px",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              gap: "15px",
                            }}
                          >
                            {" "}
                            <Typography
                              sx={{ fontSize: "14px", color: "#797D8C" }}
                            >
                              Value
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              gap: "15px",
                            }}
                          >
                            {Object.keys(analyticsData.failed).map(
                              (key) => (
                                <Box
                                  key={key}
                                  sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: "15px",
                                  }}
                                >
                                  <Typography
                                    sx={{ fontSize: "14px", color: "#797D8C" }}
                                  >
                                    {
                                      analyticsData.failed[
                                        key as keyof typeof analyticsData.failed
                                      ].value
                                    }
                                  </Typography>
                                </Box>
                              )
                            )}
                            <Typography
                              sx={{
                                fontSize: "14px",
                                color: "#797D8C",
                                fontWeight: "700",
                              }}
                            ></Typography>
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "12px",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              gap: "15px",
                            }}
                          >
                            <Typography
                              sx={{ fontSize: "14px", color: "#797D8C" }}
                            >
                              %
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              gap: "15px",
                            }}
                          >
                            {Object.keys(analyticsData.successful).map(
                              (key) => (
                                <Box
                                  key={key}
                                  sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: "15px",
                                  }}
                                >
                                  <Typography
                                    sx={{ fontSize: "14px", color: "#797D8C" }}
                                  >
                                    {
                                      analyticsData.successful[
                                        key as keyof typeof analyticsData.failed
                                      ].percentage
                                    }
                                    %
                                  </Typography>
                                </Box>
                              )
                            )}
                            <Typography
                              sx={{
                                fontSize: "14px",
                                color: "#797D8C",
                                fontWeight: "700",
                              }}
                            ></Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                )}
              </Box>
              {/* CHARTS */}
              {/* <Box
                sx={{
                  background: "#FFFFFF",
                  padding: "14px 25px",
                  display: "flex",
                  flexDirection: "column",
                  gap: "30px",
                }}
              >
                <Typography
                  sx={{ fontSize: "14px", color: "#797D8C", textAlign: "left" }}
                >
                  Statistic:
                </Typography>
                <Tabs
                  // value={value}
                  // onChange={handleChange}
                  aria-label="basic tabs example"
                  sx={{
                    borderBottom: "none", // Remove the default bottom border
                  }}
                >
                  <Tab
                    label="T"
                    className={value === 0 ? "activeTab" : ""}
                    sx={{
                      border:
                        value === 0
                          ? "#F59E0B 1px solid"
                          : "#EFF1F6  1px solid",
                      borderRadius: "8px",
                      fontSize: "14px",
                      lineHeight: "16px",
                      textTransform: "capitalize",
                      marginRight: "12px",
                      backgroundColor: value === 0 ? "#FCDFAE" : "inherit",
                    }}
                    {...a11yProps(0)}
                  />
                  <Tab
                    label="Y"
                    className={value === 1 ? "activeTab" : ""}
                    sx={{
                      border:
                        value === 1
                          ? "#F59E0B 1px solid"
                          : "#EFF1F6  1px solid",
                      borderRadius: "8px",
                      fontSize: "14px",
                      lineHeight: "16px",
                      textTransform: "capitalize",
                      marginRight: "12px",
                      backgroundColor: value === 1 ? "#FCDFAE" : "inherit",
                    }}
                    {...a11yProps(1)}
                  />
                  <Tab
                    label="TW"
                    className={value === 2 ? "activeTab" : ""}
                    sx={{
                      border:
                        value === 2
                          ? "#F59E0B 1px solid"
                          : "#EFF1F6  1px solid",
                      borderRadius: "8px",
                      fontSize: "14px",
                      lineHeight: "16px",
                      textTransform: "capitalize",
                      marginRight: "12px",
                      backgroundColor: value === 2 ? "#FCDFAE" : "inherit",
                    }}
                    {...a11yProps(2)}
                  />
                  <Tab
                    label="TM"
                    className={value === 2 ? "activeTab" : ""}
                    sx={{
                      border:
                        value === 2
                          ? "#F59E0B 1px solid"
                          : "#EFF1F6  1px solid",
                      borderRadius: "8px",
                      fontSize: "14px",
                      lineHeight: "16px",
                      textTransform: "capitalize",
                      marginRight: "12px",
                      backgroundColor: value === 2 ? "#FCDFAE" : "inherit",
                    }}
                    {...a11yProps(2)}
                  />
                </Tabs>
              </Box> */} 
              <Box></Box>
            </Box>
          </TabPanel>
          <TabPanel value={value} index={3}>
          <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "2fr 1fr",
                gap: "30px",
              }}
            >
              <Box
                sx={{
                  background: "#FFFFFF",
                  padding: "14px 25px",
                  display: "flex",
                  flexDirection: "column",
                  gap: "30px",
                }}
              >
                <Typography
                  sx={{ fontSize: "14px", color: "#797D8C", textAlign: "left" }}
                >
                  Payments by transaction type:
                </Typography>

                {PaymentAnalysticsLoading ? (
                 <Box> <CircularProgress /> </Box>
                ) : analyticsData === undefined ? (
                  <Typography>No Data</Typography>
                ) : (
                  <Box
                    sx={{
                      display: "grid",
                      gridTemplateColumns: "0.5fr 1fr 1fr",
                      gap: "30px",
                      textAlign: "left",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "12px",
                      }}
                    >
                      <Typography sx={{ fontSize: "16px", color: "#FFF" }}>
                        .
                      </Typography>
                      <Typography sx={{ fontSize: "16px", color: "#FFF" }}>
                        .
                      </Typography>
                      {Object.keys(analyticsData.successful).map((key) => (
                        <Box
                          key={key}
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            gap: "15px",
                          }}
                        >
                          <Typography
                            sx={{ fontSize: "14px", color: "#797D8C" }}
                          >
                            {
                              analyticsData.successful[
                                key as keyof typeof analyticsData.successful
                              ].count
                            }
                          </Typography>
                        </Box>
                      ))}
                      {/* <Typography sx={{ fontSize: "16px", color: "#56616B" }}>
                      Collection
                    </Typography>
                    <Typography sx={{ fontSize: "16px", color: "#56616B" }}>
                      Disbursement
                    </Typography>
                    <Typography sx={{ fontSize: "16px", color: "#56616B" }}>
                      Refund
                    </Typography>
                    <Typography sx={{ fontSize: "16px", color: "#56616B" }}>
                      Payout
                    </Typography>
                    <Typography sx={{ fontSize: "16px", color: "#56616B" }}>
                      Transfer
                    </Typography>
                    <Typography sx={{ fontSize: "16px", color: "#56616B" }}>
                      Top up
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "16px",
                        color: "#56616B",
                        fontWeight: "700",
                      }}
                    >
                      All
                    </Typography> */}
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "20px",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#797D8C",
                          border: "1px solid #1FAF38",
                          background: "rgba(31, 175, 56, 0.1)",
                          height: "20px",
                          borderRadius: "8px",
                          fontSize: "14px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        Succesful
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          gap: "15px",
                          justifyContent: "space-between",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "12px",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              gap: "15px",
                            }}
                          >
                            {" "}
                            <Typography
                              sx={{ fontSize: "14px", color: "#797D8C" }}
                            >
                              Count
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              gap: "15px",
                            }}
                          >
                            {Object.keys(analyticsData.successful).map(
                              (key) => (
                                <Box
                                  key={key}
                                  sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: "15px",
                                  }}
                                >
                                  <Typography
                                    sx={{ fontSize: "14px", color: "#797D8C" }}
                                  >
                                    {
                                      analyticsData.successful[
                                        key as keyof typeof analyticsData.successful
                                      ].count
                                    }
                                  </Typography>
                                </Box>
                              )
                            )}
                            <Typography
                              sx={{
                                fontSize: "14px",
                                color: "#797D8C",
                                fontWeight: "700",
                              }}
                            ></Typography>
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "12px",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              gap: "15px",
                            }}
                          >
                            {" "}
                            <Typography
                              sx={{ fontSize: "14px", color: "#797D8C" }}
                            >
                              Value
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              gap: "15px",
                            }}
                          >
                            {Object.keys(analyticsData.successful).map(
                              (key) => (
                                <Box
                                  key={key}
                                  sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: "15px",
                                  }}
                                >
                                  <Typography
                                    sx={{ fontSize: "14px", color: "#797D8C" }}
                                  >
                                    {
                                      analyticsData.successful[
                                        key as keyof typeof analyticsData.successful
                                      ].value
                                    }
                                  </Typography>
                                </Box>
                              )
                            )}
                            <Typography
                              sx={{
                                fontSize: "14px",
                                color: "#797D8C",
                                fontWeight: "700",
                              }}
                            ></Typography>
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "12px",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              gap: "15px",
                            }}
                          >
                            <Typography
                              sx={{ fontSize: "14px", color: "#797D8C" }}
                            >
                              %
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              gap: "15px",
                            }}
                          >
                            {Object.keys(analyticsData.successful).map(
                              (key) => (
                                <Box
                                  key={key}
                                  sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: "15px",
                                  }}
                                >
                                  <Typography
                                    sx={{ fontSize: "14px", color: "#797D8C" }}
                                  >
                                    {
                                      analyticsData.successful[
                                        key as keyof typeof analyticsData.successful
                                      ].percentage
                                    }
                                    %
                                  </Typography>
                                </Box>
                              )
                            )}
                            <Typography
                              sx={{
                                fontSize: "14px",
                                color: "#797D8C",
                                fontWeight: "700",
                              }}
                            ></Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "20px",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#797D8C",
                          border: "1px solid #FF5403",
                          background: "rgba(255, 84, 3, 0.1)",
                          height: "20px",
                          borderRadius: "8px",
                          fontSize: "14px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        Failed
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          gap: "15px",
                          justifyContent: "space-between",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "12px",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              gap: "15px",
                            }}
                          >
                            {" "}
                            <Typography
                              sx={{ fontSize: "14px", color: "#797D8C" }}
                            >
                              Count
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              gap: "15px",
                            }}
                          >
                            {Object.keys(analyticsData.successful).map(
                              (key) => (
                                <Box
                                  key={key}
                                  sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: "15px",
                                  }}
                                >
                                  <Typography
                                    sx={{ fontSize: "14px", color: "#797D8C" }}
                                  >
                                    {
                                      analyticsData.successful[
                                        key as keyof typeof analyticsData.failed
                                      ].count
                                    }
                                  </Typography>
                                </Box>
                              )
                            )}
                            <Typography
                              sx={{
                                fontSize: "14px",
                                color: "#797D8C",
                                fontWeight: "700",
                              }}
                            ></Typography>
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "12px",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              gap: "15px",
                            }}
                          >
                            {" "}
                            <Typography
                              sx={{ fontSize: "14px", color: "#797D8C" }}
                            >
                              Value
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              gap: "15px",
                            }}
                          >
                            {Object.keys(analyticsData.successful).map(
                              (key) => (
                                <Box
                                  key={key}
                                  sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: "15px",
                                  }}
                                >
                                  <Typography
                                    sx={{ fontSize: "14px", color: "#797D8C" }}
                                  >
                                    {
                                      analyticsData.successful[
                                        key as keyof typeof analyticsData.failed
                                      ].value
                                    }
                                  </Typography>
                                </Box>
                              )
                            )}
                            <Typography
                              sx={{
                                fontSize: "14px",
                                color: "#797D8C",
                                fontWeight: "700",
                              }}
                            ></Typography>
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "12px",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              gap: "15px",
                            }}
                          >
                            <Typography
                              sx={{ fontSize: "14px", color: "#797D8C" }}
                            >
                              %
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              gap: "15px",
                            }}
                          >
                            {Object.keys(analyticsData.successful).map(
                              (key) => (
                                <Box
                                  key={key}
                                  sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: "15px",
                                  }}
                                >
                                  <Typography
                                    sx={{ fontSize: "14px", color: "#797D8C" }}
                                  >
                                    {
                                      analyticsData.successful[
                                        key as keyof typeof analyticsData.failed
                                      ].percentage
                                    }
                                    %
                                  </Typography>
                                </Box>
                              )
                            )}
                            <Typography
                              sx={{
                                fontSize: "14px",
                                color: "#797D8C",
                                fontWeight: "700",
                              }}
                            ></Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                )}
              </Box> 
              <Box></Box>
            </Box>
          </TabPanel>
          <TabPanel value={value} index={4}>
          <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "2fr 1fr",
                gap: "30px",
              }}
            >
              <Box
                sx={{
                  background: "#FFFFFF",
                  padding: "14px 25px",
                  display: "flex",
                  flexDirection: "column",
                  gap: "30px",
                }}
              >
                <Typography
                  sx={{ fontSize: "14px", color: "#797D8C", textAlign: "left" }}
                >
                  Payments by transaction type:
                </Typography>

                {PaymentAnalysticsLoading ? (
                  <Box> <CircularProgress /> </Box>
                ) : analyticsData === undefined ? (
                  <Typography>No Data</Typography>
                ) : (
                  <Box
                    sx={{
                      display: "grid",
                      gridTemplateColumns: "0.5fr 1fr 1fr",
                      gap: "30px",
                      textAlign: "left",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "12px",
                      }}
                    >
                      <Typography sx={{ fontSize: "16px", color: "#FFF" }}>
                        .
                      </Typography>
                      <Typography sx={{ fontSize: "16px", color: "#FFF" }}>
                        .
                      </Typography>
                      {Object.keys(analyticsData.successful).map((key) => (
                        <Box
                          key={key}
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            gap: "15px",
                          }}
                        >
                          <Typography
                            sx={{ fontSize: "14px", color: "#797D8C" }}
                          >
                            {
                              analyticsData.successful[
                                key as keyof typeof analyticsData.successful
                              ].count
                            }
                          </Typography>
                        </Box>
                      ))}
                      {/* <Typography sx={{ fontSize: "16px", color: "#56616B" }}>
                      Collection
                    </Typography>
                    <Typography sx={{ fontSize: "16px", color: "#56616B" }}>
                      Disbursement
                    </Typography>
                    <Typography sx={{ fontSize: "16px", color: "#56616B" }}>
                      Refund
                    </Typography>
                    <Typography sx={{ fontSize: "16px", color: "#56616B" }}>
                      Payout
                    </Typography>
                    <Typography sx={{ fontSize: "16px", color: "#56616B" }}>
                      Transfer
                    </Typography>
                    <Typography sx={{ fontSize: "16px", color: "#56616B" }}>
                      Top up
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "16px",
                        color: "#56616B",
                        fontWeight: "700",
                      }}
                    >
                      All
                    </Typography> */}
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "20px",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#797D8C",
                          border: "1px solid #1FAF38",
                          background: "rgba(31, 175, 56, 0.1)",
                          height: "20px",
                          borderRadius: "8px",
                          fontSize: "14px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        Succesful
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          gap: "15px",
                          justifyContent: "space-between",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "12px",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              gap: "15px",
                            }}
                          >
                            {" "}
                            <Typography
                              sx={{ fontSize: "14px", color: "#797D8C" }}
                            >
                              Count
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              gap: "15px",
                            }}
                          >
                            {Object.keys(analyticsData.successful).map(
                              (key) => (
                                <Box
                                  key={key}
                                  sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: "15px",
                                  }}
                                >
                                  <Typography
                                    sx={{ fontSize: "14px", color: "#797D8C" }}
                                  >
                                    {
                                      analyticsData.successful[
                                        key as keyof typeof analyticsData.successful
                                      ].count
                                    }
                                  </Typography>
                                </Box>
                              )
                            )}
                            <Typography
                              sx={{
                                fontSize: "14px",
                                color: "#797D8C",
                                fontWeight: "700",
                              }}
                            ></Typography>
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "12px",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              gap: "15px",
                            }}
                          >
                            {" "}
                            <Typography
                              sx={{ fontSize: "14px", color: "#797D8C" }}
                            >
                              Value
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              gap: "15px",
                            }}
                          >
                            {Object.keys(analyticsData.successful).map(
                              (key) => (
                                <Box
                                  key={key}
                                  sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: "15px",
                                  }}
                                >
                                  <Typography
                                    sx={{ fontSize: "14px", color: "#797D8C" }}
                                  >
                                    {
                                      analyticsData.successful[
                                        key as keyof typeof analyticsData.successful
                                      ].value
                                    }
                                  </Typography>
                                </Box>
                              )
                            )}
                            <Typography
                              sx={{
                                fontSize: "14px",
                                color: "#797D8C",
                                fontWeight: "700",
                              }}
                            ></Typography>
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "12px",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              gap: "15px",
                            }}
                          >
                            <Typography
                              sx={{ fontSize: "14px", color: "#797D8C" }}
                            >
                              %
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              gap: "15px",
                            }}
                          >
                            {Object.keys(analyticsData.successful).map(
                              (key) => (
                                <Box
                                  key={key}
                                  sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: "15px",
                                  }}
                                >
                                  <Typography
                                    sx={{ fontSize: "14px", color: "#797D8C" }}
                                  >
                                    {
                                      analyticsData.successful[
                                        key as keyof typeof analyticsData.successful
                                      ].percentage
                                    }
                                    %
                                  </Typography>
                                </Box>
                              )
                            )}
                            <Typography
                              sx={{
                                fontSize: "14px",
                                color: "#797D8C",
                                fontWeight: "700",
                              }}
                            ></Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "20px",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#797D8C",
                          border: "1px solid #FF5403",
                          background: "rgba(255, 84, 3, 0.1)",
                          height: "20px",
                          borderRadius: "8px",
                          fontSize: "14px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        Failed
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          gap: "15px",
                          justifyContent: "space-between",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "12px",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              gap: "15px",
                            }}
                          >
                            {" "}
                            <Typography
                              sx={{ fontSize: "14px", color: "#797D8C" }}
                            >
                              Count
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              gap: "15px",
                            }}
                          >
                            {Object.keys(analyticsData.successful).map(
                              (key) => (
                                <Box
                                  key={key}
                                  sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: "15px",
                                  }}
                                >
                                  <Typography
                                    sx={{ fontSize: "14px", color: "#797D8C" }}
                                  >
                                    {
                                      analyticsData.successful[
                                        key as keyof typeof analyticsData.failed
                                      ].count
                                    }
                                  </Typography>
                                </Box>
                              )
                            )}
                            <Typography
                              sx={{
                                fontSize: "14px",
                                color: "#797D8C",
                                fontWeight: "700",
                              }}
                            ></Typography>
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "12px",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              gap: "15px",
                            }}
                          >
                            {" "}
                            <Typography
                              sx={{ fontSize: "14px", color: "#797D8C" }}
                            >
                              Value
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              gap: "15px",
                            }}
                          >
                            {Object.keys(analyticsData.successful).map(
                              (key) => (
                                <Box
                                  key={key}
                                  sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: "15px",
                                  }}
                                >
                                  <Typography
                                    sx={{ fontSize: "14px", color: "#797D8C" }}
                                  >
                                    {
                                      analyticsData.successful[
                                        key as keyof typeof analyticsData.failed
                                      ].value
                                    }
                                  </Typography>
                                </Box>
                              )
                            )}
                            <Typography
                              sx={{
                                fontSize: "14px",
                                color: "#797D8C",
                                fontWeight: "700",
                              }}
                            ></Typography>
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "12px",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              gap: "15px",
                            }}
                          >
                            <Typography
                              sx={{ fontSize: "14px", color: "#797D8C" }}
                            >
                              %
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              gap: "15px",
                            }}
                          >
                            {Object.keys(analyticsData.successful).map(
                              (key) => (
                                <Box
                                  key={key}
                                  sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: "15px",
                                  }}
                                >
                                  <Typography
                                    sx={{ fontSize: "14px", color: "#797D8C" }}
                                  >
                                    {
                                      analyticsData.successful[
                                        key as keyof typeof analyticsData.failed
                                      ].percentage
                                    }
                                    %
                                  </Typography>
                                </Box>
                              )
                            )}
                            <Typography
                              sx={{
                                fontSize: "14px",
                                color: "#797D8C",
                                fontWeight: "700",
                              }}
                            ></Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                )}
              </Box> 
              <Box></Box>
            </Box>
          </TabPanel>
          <TabPanel value={value} index={5}>
            {/* CUSTOM DATE */}
            <Box sx={{   display: "grid",
                    gridTemplateColumns: "repeat(2, 1fr)",
                    gridGap: "1rem",}}>
            <Box sx={{display: 'flex', flexDirection: 'column',width: '100%', backgroundColor: "#FFFFFF",
                    border: "solid 1px #E0E0E0",
                    borderRadius: "8px",padding: "14px 25px", marginBottom: '20px'}}>
              <Typography
                sx={{
                  color: "#797D8C",
                  fontSize: "12px",
                  fontWeight: 500,
                  textAlign: 'left'
                }}
              >
                Choose Custom Date:
              </Typography>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  // justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  gap: "20px",
                }}
              >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      position: "relative",width: '100%'
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                        width: '100%'
                      }}
                    >
                      <DemoContainer components={["DatePicker"]}>
                        <DatePicker
                          label="Start Date"
                          value={startDate}
                          onChange={handleStartDateChange}
                          sx={{width: '100%'}}
                        />
                      </DemoContainer>
                      <Box sx={{ width: "15px", border: "#000 solid 1px" }} />
                      <DemoContainer components={["DatePicker"]}>
                        <DatePicker
                          label="End Date"
                          value={endDate}
                          onChange={handleEndDateChange}
                          sx={{width: '100%'}}
                        />
                      </DemoContainer>
                    </Box>
                  </Box>
                </LocalizationProvider>
                <Button
                    sx={{
                      height: "55px",
                      backgroundColor: "#F59E0B",
                      "&:hover": {
                        backgroundColor: "#E18D00", // Change this to your desired hover color
                      },
                    }}
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={handleCustomDate}
                  >
                    Search
                  </Button> 
              </Box>
            </Box></Box>
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "2fr 1fr",
                gap: "30px",
              }}
            >
              <Box
                sx={{
                  background: "#FFFFFF",
                  padding: "14px 25px",
                  display: "flex",
                  flexDirection: "column",
                  gap: "30px",
                }}
              >
                <Typography
                  sx={{ fontSize: "14px", color: "#797D8C", textAlign: "left" }}
                >
                  Payments by transaction type:
                </Typography>

                {PaymentAnalysticsLoading ? (
                 <Box> <CircularProgress /> </Box>
                ) : analyticsData === undefined ? (
                  <Typography>No Data</Typography>
                ) : (
                  <Box
                    sx={{
                      display: "grid",
                      gridTemplateColumns: "0.5fr 1fr 1fr",
                      gap: "30px",
                      textAlign: "left",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "12px",
                      }}
                    >
                      <Typography sx={{ fontSize: "16px", color: "#FFF" }}>
                        .
                      </Typography>
                      <Typography sx={{ fontSize: "16px", color: "#FFF" }}>
                        .
                      </Typography>
                      {Object.keys(analyticsData.successful).map((key) => (
                        <Box
                          key={key}
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            gap: "15px",
                          }}
                        >
                          <Typography
                            sx={{ fontSize: "14px", color: "#797D8C" }}
                          >
                            {
                              analyticsData.successful[
                                key as keyof typeof analyticsData.successful
                              ].count
                            }
                          </Typography>
                        </Box>
                      ))}
                      {/* <Typography sx={{ fontSize: "16px", color: "#56616B" }}>
                      Collection
                    </Typography>
                    <Typography sx={{ fontSize: "16px", color: "#56616B" }}>
                      Disbursement
                    </Typography>
                    <Typography sx={{ fontSize: "16px", color: "#56616B" }}>
                      Refund
                    </Typography>
                    <Typography sx={{ fontSize: "16px", color: "#56616B" }}>
                      Payout
                    </Typography>
                    <Typography sx={{ fontSize: "16px", color: "#56616B" }}>
                      Transfer
                    </Typography>
                    <Typography sx={{ fontSize: "16px", color: "#56616B" }}>
                      Top up
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "16px",
                        color: "#56616B",
                        fontWeight: "700",
                      }}
                    >
                      All
                    </Typography> */}
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "20px",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#797D8C",
                          border: "1px solid #1FAF38",
                          background: "rgba(31, 175, 56, 0.1)",
                          height: "20px",
                          borderRadius: "8px",
                          fontSize: "14px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        Succesful
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          gap: "15px",
                          justifyContent: "space-between",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "12px",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              gap: "15px",
                            }}
                          >
                            {" "}
                            <Typography
                              sx={{ fontSize: "14px", color: "#797D8C" }}
                            >
                              Count
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              gap: "15px",
                            }}
                          >
                            {Object.keys(analyticsData.successful).map(
                              (key) => (
                                <Box
                                  key={key}
                                  sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: "15px",
                                  }}
                                >
                                  <Typography
                                    sx={{ fontSize: "14px", color: "#797D8C" }}
                                  >
                                    {
                                      analyticsData.successful[
                                        key as keyof typeof analyticsData.successful
                                      ].count
                                    }
                                  </Typography>
                                </Box>
                              )
                            )}
                            <Typography
                              sx={{
                                fontSize: "14px",
                                color: "#797D8C",
                                fontWeight: "700",
                              }}
                            ></Typography>
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "12px",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              gap: "15px",
                            }}
                          >
                            {" "}
                            <Typography
                              sx={{ fontSize: "14px", color: "#797D8C" }}
                            >
                              Value
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              gap: "15px",
                            }}
                          >
                            {Object.keys(analyticsData.successful).map(
                              (key) => (
                                <Box
                                  key={key}
                                  sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: "15px",
                                  }}
                                >
                                  <Typography
                                    sx={{ fontSize: "14px", color: "#797D8C" }}
                                  >
                                    {
                                      analyticsData.successful[
                                        key as keyof typeof analyticsData.successful
                                      ].value
                                    }
                                  </Typography>
                                </Box>
                              )
                            )}
                            <Typography
                              sx={{
                                fontSize: "14px",
                                color: "#797D8C",
                                fontWeight: "700",
                              }}
                            ></Typography>
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "12px",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              gap: "15px",
                            }}
                          >
                            <Typography
                              sx={{ fontSize: "14px", color: "#797D8C" }}
                            >
                              %
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              gap: "15px",
                            }}
                          >
                            {Object.keys(analyticsData.successful).map(
                              (key) => (
                                <Box
                                  key={key}
                                  sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: "15px",
                                  }}
                                >
                                  <Typography
                                    sx={{ fontSize: "14px", color: "#797D8C" }}
                                  >
                                    {
                                      analyticsData.successful[
                                        key as keyof typeof analyticsData.successful
                                      ].percentage
                                    }
                                    %
                                  </Typography>
                                </Box>
                              )
                            )}
                            <Typography
                              sx={{
                                fontSize: "14px",
                                color: "#797D8C",
                                fontWeight: "700",
                              }}
                            ></Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "20px",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#797D8C",
                          border: "1px solid #FF5403",
                          background: "rgba(255, 84, 3, 0.1)",
                          height: "20px",
                          borderRadius: "8px",
                          fontSize: "14px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        Failed
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          gap: "15px",
                          justifyContent: "space-between",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "12px",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              gap: "15px",
                            }}
                          >
                            {" "}
                            <Typography
                              sx={{ fontSize: "14px", color: "#797D8C" }}
                            >
                              Count
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              gap: "15px",
                            }}
                          >
                            {Object.keys(analyticsData.successful).map(
                              (key) => (
                                <Box
                                  key={key}
                                  sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: "15px",
                                  }}
                                >
                                  <Typography
                                    sx={{ fontSize: "14px", color: "#797D8C" }}
                                  >
                                    {
                                      analyticsData.successful[
                                        key as keyof typeof analyticsData.failed
                                      ].count
                                    }
                                  </Typography>
                                </Box>
                              )
                            )}
                            <Typography
                              sx={{
                                fontSize: "14px",
                                color: "#797D8C",
                                fontWeight: "700",
                              }}
                            ></Typography>
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "12px",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              gap: "15px",
                            }}
                          >
                            {" "}
                            <Typography
                              sx={{ fontSize: "14px", color: "#797D8C" }}
                            >
                              Value
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              gap: "15px",
                            }}
                          >
                            {Object.keys(analyticsData.successful).map(
                              (key) => (
                                <Box
                                  key={key}
                                  sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: "15px",
                                  }}
                                >
                                  <Typography
                                    sx={{ fontSize: "14px", color: "#797D8C" }}
                                  >
                                    {
                                      analyticsData.successful[
                                        key as keyof typeof analyticsData.failed
                                      ].value
                                    }
                                  </Typography>
                                </Box>
                              )
                            )}
                            <Typography
                              sx={{
                                fontSize: "14px",
                                color: "#797D8C",
                                fontWeight: "700",
                              }}
                            ></Typography>
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "12px",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              gap: "15px",
                            }}
                          >
                            <Typography
                              sx={{ fontSize: "14px", color: "#797D8C" }}
                            >
                              %
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              gap: "15px",
                            }}
                          >
                            {Object.keys(analyticsData.successful).map(
                              (key) => (
                                <Box
                                  key={key}
                                  sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: "15px",
                                  }}
                                >
                                  <Typography
                                    sx={{ fontSize: "14px", color: "#797D8C" }}
                                  >
                                    {
                                      analyticsData.successful[
                                        key as keyof typeof analyticsData.failed
                                      ].percentage
                                    }
                                    %
                                  </Typography>
                                </Box>
                              )
                            )}
                            <Typography
                              sx={{
                                fontSize: "14px",
                                color: "#797D8C",
                                fontWeight: "700",
                              }}
                            ></Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                )}
              </Box> 
              <Box></Box>
            </Box>
          </TabPanel>
        </Box>
      </Box>
      {/* Top 10 Merchants Table */}
      <Box>
        <TopMerchants
          data={PaymentAnalysticsTopMerchants}
          tableTitle={`${operator} Top Merchants`}
        />
      </Box>
    </Box>
  );
};

export default FinanceOverview;
