import React, { useState, useEffect } from "react";
import { Box, Typography, CardMedia, Skeleton, Button } from "@mui/material";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import MerchantOverviewTable from "./MerchantOverviewTable";
import {
  TogoIcon,
  BeninIcon,
  CotedivoireIcon,
  individualBT,
  registeredBT,
  noBT,
  activeKYC,
  inactiveKYC,
  activeStatus,
  inactiveStatus,
  suspendedStatus,
  verifiedEmail,
  unverifiedEmail,
  MerchantIcon,
  twoFALock,
} from "../../../../common/assets/icons";
import { useAppDispatch, useAppSelector } from "../../../../state/hooks";
import { GetMerchantAnalystics } from "../../../../state/feautures/user/getMerchantAnalysticsSlice";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";

// Tab Functions
function TabPanel(props: any) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
interface Credentials {
  startDate: string;
  endDate: string;
}

const Index = () => {
  const dispatch = useAppDispatch();
  const MerchantAnalystics = useAppSelector(
    (state: any) => state.merchAntanalystics
  );
  const MerchantAnalysticsCacheStatus = MerchantAnalystics.cacheStatus;
  const MerchantAnalysticsData = MerchantAnalystics.data;
  const loading = MerchantAnalystics.loading;
  const [value, setValue] = useState<number>(0);
  const [customDate, setCustomDate] = useState(false);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const formatDate = (date: Date | null) => {
    return date ? dayjs(date).format("YYYY-MM-DD") : "";
  };
  const handleStartDateChange = (date: Date | null) => { 
      setStartDate(date); 
  };

  const handleEndDateChange = (date: Date | null) => { 
      setEndDate(date); 
  };

  const handleChange = (event: any, newValue: any) => {
    setValue(newValue);
  };
  useEffect(() => {
    // if (MerchantAnalysticsCacheStatus === "idle") {
    const getDateRangeForValue = (
      value: number
    ): { start: string; end: string } => {
      const today = new Date();
      const formatDate = (date: Date): string => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const day = String(date.getDate()).padStart(2, "0");
        return `${year}-${month}-${day}`;
      };

      // Initialize startDate and endDate
      let startDate: Date;
      let endDate: Date;

      switch (value) {
        case 0:
          startDate = today;
          endDate = today;
          break;
        case 1:
          const dayOfWeek = today.getDay();
          const startOfWeek = 0; // Sunday
          const endOfWeek = 6; // Saturday

          startDate = new Date(today);
          startDate.setDate(today.getDate() - dayOfWeek + startOfWeek);

          endDate = new Date(today);
          endDate.setDate(today.getDate() + (endOfWeek - dayOfWeek));
          break;
        case 2:
          startDate = new Date(today.getFullYear(), today.getMonth(), 1);
          endDate = new Date(today.getFullYear(), today.getMonth() + 1, 0);
          break;
        case 3:
          const quarter = Math.floor(today.getMonth() / 3);
          switch (quarter) {
            case 0:
              startDate = new Date(today.getFullYear(), 0, 1); // Q1 start
              endDate = new Date(today.getFullYear(), 2, 31); // Q1 end
              break;
            case 1:
              startDate = new Date(today.getFullYear(), 3, 1); // Q2 start
              endDate = new Date(today.getFullYear(), 5, 30); // Q2 end
              break;
            case 2:
              startDate = new Date(today.getFullYear(), 6, 1); // Q3 start
              endDate = new Date(today.getFullYear(), 8, 30); // Q3 end
              break;
            case 3:
              startDate = new Date(today.getFullYear(), 9, 1); // Q4 start
              endDate = new Date(today.getFullYear(), 11, 31); // Q4 end
              break;
            default:
              throw new Error("Invalid Quartar");
          }
          break;
        case 4:
          startDate = new Date("1970-01-01");
          endDate = today;
          break;
        default:
          startDate = new Date("");
          endDate = new Date("");
      }
      return {
        start: formatDate(startDate),
        end: formatDate(endDate),
      };
    };
    const dateRange = getDateRangeForValue(value);
    const requestCredentials: Credentials = {
      startDate: dateRange.start,
      endDate: dateRange.end,
    };
    if (value !== 5){
          dispatch(GetMerchantAnalystics(requestCredentials));
    }
  }, [dispatch, MerchantAnalysticsCacheStatus, value]);

  const handleCustomDate = () => {
    setCustomDate(!customDate);
    const requestCredentials: Credentials = {
      startDate:formatDate(startDate),
      endDate: formatDate(endDate),
    };
    dispatch(GetMerchantAnalystics(requestCredentials))
  };
  return (
    <Box sx={{ width: "100%" }}>
      {/* Heading */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "start",
          alignItems: "start",
          marginBottom: "20px",
        }}
      >
        <Typography
          sx={{ fontSize: "24px", color: "#45464E", marginBottom: "-5px" }}
        >
          Merchants Overview
        </Typography>
        <Typography
          sx={{
            fontSize: "16px",
            color: "#B2B2B2",
            fontWeight: 500,
            marginTop: "0",
          }}
        >
          Checkout your Merchant Overview
        </Typography>
      </Box>
      <Typography
        sx={{
          fontSize: "16px",
          color: "#45464E",
          fontWeight: 700,
          marginTop: "0",
          textAlign: "left",
        }}
      >
        {value === 0
          ? "Today"
          : value === 1
          ? "This Week"
          : value === 2
          ? "This Month"
          : value === 3
          ? "This Quarter"
          : value === 4
          ? "All Time"
          : "Custom"}
      </Typography>
      {/* Tabs Head*/}
      <Box sx={{ borderBottom: 0, marginBottom: "15px", marginTop: "15px" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          sx={{
            borderBottom: "none", // Remove the default bottom border
          }}
        >
          <Tab
            label="Today"
            className={value === 0 ? "activeTab" : ""}
            sx={{
              border: value === 0 ? "#F59E0B 1px solid" : "#EFF1F6  1px solid",
              borderRadius: "8px",
              fontSize: "14px",
              lineHeight: "16px",
              textTransform: "capitalize",
              marginRight: "12px",
              backgroundColor: value === 0 ? "#FCDFAE" : "inherit",
            }}
            {...a11yProps(0)}
          />
          <Tab
            label="This Week"
            className={value === 1 ? "activeTab" : ""}
            sx={{
              border: value === 1 ? "#F59E0B 1px solid" : "#EFF1F6  1px solid",
              borderRadius: "8px",
              fontSize: "14px",
              lineHeight: "16px",
              textTransform: "capitalize",
              marginRight: "12px",
              backgroundColor: value === 1 ? "#FCDFAE" : "inherit",
            }}
            {...a11yProps(1)}
          />
          <Tab
            label="This Month"
            className={value === 2 ? "activeTab" : ""}
            sx={{
              border: value === 2 ? "#F59E0B 1px solid" : "#EFF1F6  1px solid",
              borderRadius: "8px",
              fontSize: "14px",
              lineHeight: "16px",
              textTransform: "capitalize",
              marginRight: "12px",
              backgroundColor: value === 2 ? "#FCDFAE" : "inherit",
            }}
            {...a11yProps(2)}
          />
          <Tab
            label="This Quarter"
            className={value === 3 ? "activeTab" : ""}
            sx={{
              border: value === 3 ? "#F59E0B 1px solid" : "#EFF1F6  1px solid",
              borderRadius: "8px",
              fontSize: "14px",
              lineHeight: "16px",
              textTransform: "capitalize",
              marginRight: "12px",
              backgroundColor: value === 3 ? "#FCDFAE" : "inherit",
            }}
            {...a11yProps(3)}
          />
          <Tab
            label="All Time"
            className={value === 4 ? "activeTab" : ""}
            sx={{
              border: value === 4 ? "#F59E0B 1px solid" : "#EFF1F6  1px solid",
              borderRadius: "8px",
              fontSize: "14px",
              lineHeight: "16px",
              textTransform: "capitalize",
              marginRight: "12px",
              backgroundColor: value === 4 ? "#FCDFAE" : "inherit",
            }}
            {...a11yProps(4)}
          />
          <Tab
            label="Custom Date"
            className={value === 5 ? "activeTab" : ""}
            // onClick={handleCustomDate}
            sx={{
              border: value === 5 ? "#F59E0B 1px solid" : "#EFF1F6  1px solid",
              borderRadius: "8px",
              fontSize: "14px",
              lineHeight: "16px",
              textTransform: "capitalize",
              marginRight: "12px",
              backgroundColor: value === 5 ? "#FCDFAE" : "inherit",
            }}
            {...a11yProps(5)}
          />
        </Tabs>
      </Box>

      {/* Tabs Content */}
      <Box sx={{ width: "100%" }} aria-label="scrollable auto tabs example">
        {/* All Account List */}
        <TabPanel value={value} index={0}>
          {loading ? (
            <Box sx={{}}>
              <Skeleton
                variant="rectangular"
                sx={{
                  height: "240px",
                  backgroundColor: "#FFFFFF",
                  border: "solid 1px #E0E0E0",
                  borderRadius: "8px",
                }}
                animation="wave"
              />
            </Box>
          ) : (
            <Box>
              {/* Two Cards */}
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "repeat(2, 1fr)",
                  gridGap: "1rem",
                  margin: "0",
                }}
              >
                {/* Accounts Created */}
                {loading ? (
                  <Skeleton
                    variant="rectangular"
                    sx={{
                      height: "190px",
                      backgroundColor: "#FFFFFF",
                      border: "solid 1px #E0E0E0",
                      borderRadius: "8px",
                    }}
                    animation="wave"
                  />
                ) : (
                  <Box
                    sx={{
                      height: "100%",
                      backgroundColor: "#FFFFFF",
                      border: "solid 1px #E0E0E0",
                      borderRadius: "8px",
                      padding: "14px 25px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      gap: "10px",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        width: "100%",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "baseline",
                          width: "100%",
                        }}
                      >
                        <Typography
                          sx={{
                            color: "#797D8C",
                            fontSize: "12px",
                            fontWeight: 500,
                          }}
                        >
                          Account Created:
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          gap: "10px",
                          alignItems: "center",
                        }}
                      >
                        {" "}
                        <CardMedia
                          component="img"
                          height="20px"
                          image={MerchantIcon}
                          alt="Icon"
                          sx={{
                            objectFit: "contain",
                            marginLeft: "10px",
                            width: "20px",
                          }}
                        />
                        <Typography
                          sx={{ fontSize: "18px", fontWeight: "600" }}
                        >
                          {MerchantAnalysticsData.totalAccounts
                            ? MerchantAnalysticsData.totalAccounts
                            : "N/A"}
                        </Typography>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: "grid",
                        gridTemplateColumns: "repeat(2, 1fr)",
                        width: "100%",
                        gap: "10px",
                      }}
                    >
                      <Box>
                        <Typography
                          sx={{
                            color: "#797D8C",
                            fontSize: "12px",
                            fontWeight: 500,
                            textAlign: "left",
                          }}
                        >
                          Per Country:
                        </Typography>
                        <Box
                          sx={{
                            display: "grid",
                            gridTemplateRows: "1fr 1fr 1fr",
                            gridGap: "5px",
                            justifyContent: "space-between",
                            width: "100%",
                          }}
                        >
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <CardMedia
                              component="img"
                              height="25px"
                              image={BeninIcon}
                              alt="Icon"
                              sx={{
                                objectFit: "contain",
                                marginRight: "15px",
                                width: "20px",
                              }}
                            />
                            <Typography
                              sx={{
                                fontSize: "16px",
                                fontWeight: 500,
                              }}
                            >
                              {MerchantAnalysticsData.totalAccountsInBenin
                                ? MerchantAnalysticsData.totalAccountsInBenin
                                : "N/A"}
                            </Typography>
                          </Box>
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <CardMedia
                              component="img"
                              height="25px"
                              image={TogoIcon}
                              alt="Icon"
                              sx={{
                                objectFit: "contain",
                                marginRight: "15px",
                                width: "20px",
                              }}
                            />
                            <Typography
                              sx={{
                                fontSize: "16px",
                                fontWeight: 500,
                              }}
                            >
                              {MerchantAnalysticsData.totalAccountsInTogo
                                ? MerchantAnalysticsData.totalAccountsInTogo
                                : "N/A"}
                            </Typography>
                          </Box>{" "}
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <CardMedia
                              component="img"
                              height="25px"
                              image={CotedivoireIcon}
                              alt="Icon"
                              sx={{
                                objectFit: "contain",
                                marginRight: "15px",
                                width: "20px",
                              }}
                            />
                            <Typography
                              sx={{
                                fontSize: "16px",
                                fontWeight: 500,
                              }}
                            >
                              {MerchantAnalysticsData.totalAccountsInIvoryCoast
                                ? MerchantAnalysticsData.totalAccountsInIvoryCoast
                                : "N/A"}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                      <Box>
                        <Typography
                          sx={{
                            color: "#797D8C",
                            fontSize: "12px",
                            textAlign: "left",
                            fontWeight: 500,
                          }}
                        >
                          Business Type:
                        </Typography>
                        <Box
                          sx={{
                            display: "grid",
                            gridTemplateRows: "1fr 1fr 1fr",
                            gridGap: "5px",
                            justifyContent: "space-between",
                            width: "100%",
                          }}
                        >
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <CardMedia
                              component="img"
                              height="25px"
                              image={individualBT}
                              alt="Icon"
                              sx={{
                                objectFit: "contain",
                                marginRight: "15px",
                                width: "20px",
                              }}
                            />
                            <Typography
                              sx={{
                                fontSize: "16px",
                                fontWeight: 500,
                              }}
                            >
                              N/A
                            </Typography>
                          </Box>
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <CardMedia
                              component="img"
                              height="25px"
                              image={registeredBT}
                              alt="Icon"
                              sx={{
                                objectFit: "contain",
                                marginRight: "15px",
                                width: "20px",
                              }}
                            />
                            <Typography
                              sx={{
                                fontSize: "16px",
                                fontWeight: 500,
                              }}
                            >
                              N/A
                            </Typography>
                          </Box>{" "}
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <CardMedia
                              component="img"
                              height="25px"
                              image={noBT}
                              alt="Icon"
                              sx={{
                                objectFit: "contain",
                                marginRight: "15px",
                                width: "20px",
                              }}
                            />
                            <Typography
                              sx={{
                                fontSize: "16px",
                                fontWeight: 500,
                              }}
                            >
                              N/A
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                )}
                {/* Account Information*/}
                {loading ? (
                  <Skeleton
                    variant="rectangular"
                    sx={{
                      height: "190px",
                      backgroundColor: "#FFFFFF",
                      border: "solid 1px #E0E0E0",
                      borderRadius: "8px",
                    }}
                    animation="wave"
                  />
                ) : (
                  <Box
                    sx={{
                      height: "100%",
                      backgroundColor: "#FFFFFF",
                      border: "solid 1px #E0E0E0",
                      borderRadius: "8px",
                      padding: "14px 25px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      gap: "10px",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        width: "100%",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "baseline",
                          width: "100%",
                        }}
                      >
                        <Typography
                          sx={{
                            color: "#797D8C",
                            fontSize: "12px",
                            fontWeight: 500,
                          }}
                        >
                          Account Information:
                        </Typography>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: "grid",
                        gridTemplateColumns: "repeat(2, 1fr)",
                        width: "100%",
                        gap: "10px",
                      }}
                    >
                      <Box>
                        <Typography
                          sx={{
                            color: "#797D8C",
                            fontSize: "12px",
                            fontWeight: 500,
                            textAlign: "left",
                          }}
                        >
                          KYC:
                        </Typography>
                        <Box
                          sx={{
                            display: "grid",
                            gridTemplateRows: "1fr 1fr 1fr",
                            gridGap: "5px",
                            justifyContent: "space-between",
                            width: "100%",
                          }}
                        >
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <CardMedia
                              component="img"
                              height="25px"
                              image={activeKYC}
                              alt="Icon"
                              sx={{
                                objectFit: "contain",
                                marginRight: "15px",
                                width: "20px",
                              }}
                            />
                            <Typography
                              sx={{
                                fontSize: "16px",
                                fontWeight: 500,
                              }}
                            >
                              {MerchantAnalysticsData.totalKYCVerified
                                ? MerchantAnalysticsData.totalKYCVerified
                                : "N/A"}
                            </Typography>
                          </Box>{" "}
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <CardMedia
                              component="img"
                              height="25px"
                              image={inactiveKYC}
                              alt="Icon"
                              sx={{
                                objectFit: "contain",
                                marginRight: "15px",
                                width: "20px",
                              }}
                            />
                            <Typography
                              sx={{
                                fontSize: "16px",
                                fontWeight: 500,
                              }}
                            >
                              {MerchantAnalysticsData.totalKYCUnverified
                                ? MerchantAnalysticsData.totalKYCUnverified
                                : "N/A"}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                      <Box>
                        <Typography
                          sx={{
                            color: "#797D8C",
                            fontSize: "12px",
                            fontWeight: 500,
                            textAlign: "left",
                          }}
                        >
                          Status:
                        </Typography>
                        <Box
                          sx={{
                            display: "grid",
                            gridTemplateRows: "1fr 1fr 1fr",
                            gridGap: "5px",
                            justifyContent: "space-between",
                            width: "100%",
                          }}
                        >
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <CardMedia
                              component="img"
                              height="25px"
                              image={activeStatus}
                              alt="Icon"
                              sx={{
                                objectFit: "contain",
                                marginRight: "15px",
                                width: "20px",
                              }}
                            />
                            <Typography
                              sx={{
                                fontSize: "16px",
                                fontWeight: 500,
                              }}
                            >
                              {MerchantAnalysticsData.totalActiveAccounts
                                ? MerchantAnalysticsData.totalActiveAccounts
                                : "N/A"}
                            </Typography>
                          </Box>
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <CardMedia
                              component="img"
                              height="25px"
                              image={inactiveStatus}
                              alt="Icon"
                              sx={{
                                objectFit: "contain",
                                marginRight: "15px",
                                width: "20px",
                              }}
                            />
                            <Typography
                              sx={{
                                fontSize: "16px",
                                fontWeight: 500,
                              }}
                            >
                              {MerchantAnalysticsData.totalInactiveAccounts
                                ? MerchantAnalysticsData.totalInactiveAccounts
                                : "N/A"}
                            </Typography>
                          </Box>{" "}
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <CardMedia
                              component="img"
                              height="25px"
                              image={suspendedStatus}
                              alt="Icon"
                              sx={{
                                objectFit: "contain",
                                marginRight: "15px",
                                width: "20px",
                              }}
                            />
                            <Typography
                              sx={{
                                fontSize: "16px",
                                fontWeight: 500,
                              }}
                            >
                              {MerchantAnalysticsData.totalSuspendedAccounts
                                ? MerchantAnalysticsData.totalSuspendedAccounts
                                : "N/A"}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                      <Box>
                        <Typography
                          sx={{
                            color: "#797D8C",
                            fontSize: "12px",
                            fontWeight: 500,
                            textAlign: "left",
                          }}
                        >
                          Verified Email:
                        </Typography>
                        <Box
                          sx={{
                            display: "grid",
                            gridTemplateRows: "1fr 1fr",
                            gridGap: "5px",
                            justifyContent: "space-between",
                            width: "100%",
                          }}
                        >
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <CardMedia
                              component="img"
                              height="25px"
                              image={verifiedEmail}
                              alt="Icon"
                              sx={{
                                objectFit: "contain",
                                marginRight: "15px",
                                width: "20px",
                              }}
                            />
                            <Typography
                              sx={{
                                fontSize: "16px",
                                fontWeight: 500,
                              }}
                            >
                              {MerchantAnalysticsData.totalVerifiedEmails
                                ? MerchantAnalysticsData.totalVerifiedEmails
                                : "N/A"}
                            </Typography>
                          </Box>{" "}
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <CardMedia
                              component="img"
                              height="25px"
                              image={unverifiedEmail}
                              alt="Icon"
                              sx={{
                                objectFit: "contain",
                                marginRight: "15px",
                                width: "20px",
                              }}
                            />
                            <Typography
                              sx={{
                                fontSize: "16px",
                                fontWeight: 500,
                              }}
                            >
                              {MerchantAnalysticsData.totalUnverifiedEmails
                                ? MerchantAnalysticsData.totalUnverifiedEmails
                                : "N/A"}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                      <Box>
                          <Typography
                            sx={{
                              color: "#797D8C",
                              fontSize: "12px",
                              fontWeight: 500,
                              textAlign: "left",
                            }}
                          >
                            Is Using 2FA:
                          </Typography>
                          <Box
                            sx={{
                              display: "grid",
                              gridTemplateRows: "1fr 1fr",
                              gridGap: "5px",
                              justifyContent: "space-between",
                              width: "100%",
                            }}
                          >
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                              <CardMedia
                                component="img"
                                height="20px"
                                image={twoFALock}
                                alt="Icon"
                                sx={{
                                  objectFit: "contain",
                                  marginRight: "15px",
                                  width: "20px",
                                }}
                              />
                              <Typography
                                sx={{
                                  fontSize: "16px",
                                  fontWeight: 500,
                                }}
                              >
                                {MerchantAnalysticsData.numberOfAccountWith2FA
                                  ? MerchantAnalysticsData.numberOfAccountWith2FA
                                  : "N/A"}
                              </Typography>
                            </Box>{" "}
                            <Box sx={{ display: "flex", alignItems: "center", position: 'relative' }}>
                              <Box sx={{ position: 'absolute', width: '25px', height: '2px', background: 'red',  transform: 'rotate(45deg)',left: '-2px' }}></Box>
                              <CardMedia
                                component="img"
                                height="20px"
                                image={twoFALock}
                                alt="Icon"
                                sx={{
                                  objectFit: "contain",
                                  marginRight: "15px",
                                  width: "20px",
                                }}
                              />
                              <Typography
                                sx={{
                                  fontSize: "16px",
                                  fontWeight: 500,
                                }}
                              >
                                {MerchantAnalysticsData.numberOfAccountWithout2FA
                                  ? MerchantAnalysticsData.numberOfAccountWithout2FA
                                  : "N/A"}
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                    </Box>
                  </Box>
                )}
              </Box>
              <MerchantOverviewTable
                data={MerchantAnalysticsData.accounts}
                value={value}
              />
            </Box>
          )}
        </TabPanel>
        {/* Active Account List */}
        <TabPanel value={value} index={1}>
          {loading ? (
            <Box sx={{}}>
              <Skeleton
                variant="rectangular"
                sx={{
                  height: "240px",
                  backgroundColor: "#FFFFFF",
                  border: "solid 1px #E0E0E0",
                  borderRadius: "8px",
                }}
                animation="wave"
              />
            </Box>
          ) : (
            <Box>
              {/* Two Cards */}
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "repeat(2, 1fr)",
                  gridGap: "1rem",
                  margin: "0",
                }}
              >
                {/* Accounts Created */}
                {loading ? (
                  <Skeleton
                    variant="rectangular"
                    sx={{
                      height: "190px",
                      backgroundColor: "#FFFFFF",
                      border: "solid 1px #E0E0E0",
                      borderRadius: "8px",
                    }}
                    animation="wave"
                  />
                ) : (
                  <Box
                    sx={{
                      height: "100%",
                      backgroundColor: "#FFFFFF",
                      border: "solid 1px #E0E0E0",
                      borderRadius: "8px",
                      padding: "14px 25px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      gap: "10px",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        width: "100%",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "baseline",
                          width: "100%",
                        }}
                      >
                        <Typography
                          sx={{
                            color: "#797D8C",
                            fontSize: "12px",
                            fontWeight: 500,
                          }}
                        >
                          Account Created:
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          gap: "10px",
                          alignItems: "center",
                        }}
                      >
                        {" "}
                        <CardMedia
                          component="img"
                          height="20px"
                          image={MerchantIcon}
                          alt="Icon"
                          sx={{
                            objectFit: "contain",
                            marginLeft: "10px",
                            width: "20px",
                          }}
                        />
                        <Typography
                          sx={{ fontSize: "18px", fontWeight: "600" }}
                        >
                          {MerchantAnalysticsData.totalAccounts
                            ? MerchantAnalysticsData.totalAccounts
                            : "N/A"}
                        </Typography>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: "grid",
                        gridTemplateColumns: "repeat(2, 1fr)",
                        width: "100%",
                        gap: "10px",
                      }}
                    >
                      <Box>
                        <Typography
                          sx={{
                            color: "#797D8C",
                            fontSize: "12px",
                            fontWeight: 500,
                            textAlign: "left",
                          }}
                        >
                          Per Country:
                        </Typography>
                        <Box
                          sx={{
                            display: "grid",
                            gridTemplateRows: "1fr 1fr 1fr",
                            gridGap: "5px",
                            justifyContent: "space-between",
                            width: "100%",
                          }}
                        >
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <CardMedia
                              component="img"
                              height="25px"
                              image={BeninIcon}
                              alt="Icon"
                              sx={{
                                objectFit: "contain",
                                marginRight: "15px",
                                width: "20px",
                              }}
                            />
                            <Typography
                              sx={{
                                fontSize: "16px",
                                fontWeight: 500,
                              }}
                            >
                              {MerchantAnalysticsData.totalAccountsInBenin
                                ? MerchantAnalysticsData.totalAccountsInBenin
                                : "N/A"}
                            </Typography>
                          </Box>
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <CardMedia
                              component="img"
                              height="25px"
                              image={TogoIcon}
                              alt="Icon"
                              sx={{
                                objectFit: "contain",
                                marginRight: "15px",
                                width: "20px",
                              }}
                            />
                            <Typography
                              sx={{
                                fontSize: "16px",
                                fontWeight: 500,
                              }}
                            >
                              {MerchantAnalysticsData.totalAccountsInTogo
                                ? MerchantAnalysticsData.totalAccountsInTogo
                                : "N/A"}
                            </Typography>
                          </Box>{" "}
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <CardMedia
                              component="img"
                              height="25px"
                              image={CotedivoireIcon}
                              alt="Icon"
                              sx={{
                                objectFit: "contain",
                                marginRight: "15px",
                                width: "20px",
                              }}
                            />
                            <Typography
                              sx={{
                                fontSize: "16px",
                                fontWeight: 500,
                              }}
                            >
                              {MerchantAnalysticsData.totalAccountsInIvoryCoast
                                ? MerchantAnalysticsData.totalAccountsInIvoryCoast
                                : "N/A"}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                      <Box>
                        <Typography
                          sx={{
                            color: "#797D8C",
                            fontSize: "12px",
                            textAlign: "left",
                            fontWeight: 500,
                          }}
                        >
                          Business Type:
                        </Typography>
                        <Box
                          sx={{
                            display: "grid",
                            gridTemplateRows: "1fr 1fr 1fr",
                            gridGap: "5px",
                            justifyContent: "space-between",
                            width: "100%",
                          }}
                        >
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <CardMedia
                              component="img"
                              height="25px"
                              image={individualBT}
                              alt="Icon"
                              sx={{
                                objectFit: "contain",
                                marginRight: "15px",
                                width: "20px",
                              }}
                            />
                            <Typography
                              sx={{
                                fontSize: "16px",
                                fontWeight: 500,
                              }}
                            >
                              N/A
                            </Typography>
                          </Box>
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <CardMedia
                              component="img"
                              height="25px"
                              image={registeredBT}
                              alt="Icon"
                              sx={{
                                objectFit: "contain",
                                marginRight: "15px",
                                width: "20px",
                              }}
                            />
                            <Typography
                              sx={{
                                fontSize: "16px",
                                fontWeight: 500,
                              }}
                            >
                              N/A
                            </Typography>
                          </Box>{" "}
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <CardMedia
                              component="img"
                              height="25px"
                              image={noBT}
                              alt="Icon"
                              sx={{
                                objectFit: "contain",
                                marginRight: "15px",
                                width: "20px",
                              }}
                            />
                            <Typography
                              sx={{
                                fontSize: "16px",
                                fontWeight: 500,
                              }}
                            >
                              N/A
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                )}
                {/* Account Information*/}
                {loading ? (
                  <Skeleton
                    variant="rectangular"
                    sx={{
                      height: "190px",
                      backgroundColor: "#FFFFFF",
                      border: "solid 1px #E0E0E0",
                      borderRadius: "8px",
                    }}
                    animation="wave"
                  />
                ) : (
                  <Box
                    sx={{
                      height: "100%",
                      backgroundColor: "#FFFFFF",
                      border: "solid 1px #E0E0E0",
                      borderRadius: "8px",
                      padding: "14px 25px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      gap: "10px",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        width: "100%",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "baseline",
                          width: "100%",
                        }}
                      >
                        <Typography
                          sx={{
                            color: "#797D8C",
                            fontSize: "12px",
                            fontWeight: 500,
                          }}
                        >
                          Account Information:
                        </Typography>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: "grid",
                        gridTemplateColumns: "repeat(2, 1fr)",
                        width: "100%",
                        gap: "10px",
                      }}
                    >
                      <Box>
                        <Typography
                          sx={{
                            color: "#797D8C",
                            fontSize: "12px",
                            fontWeight: 500,
                            textAlign: "left",
                          }}
                        >
                          KYC:
                        </Typography>
                        <Box
                          sx={{
                            display: "grid",
                            gridTemplateRows: "1fr 1fr 1fr",
                            gridGap: "5px",
                            justifyContent: "space-between",
                            width: "100%",
                          }}
                        >
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <CardMedia
                              component="img"
                              height="25px"
                              image={activeKYC}
                              alt="Icon"
                              sx={{
                                objectFit: "contain",
                                marginRight: "15px",
                                width: "20px",
                              }}
                            />
                            <Typography
                              sx={{
                                fontSize: "16px",
                                fontWeight: 500,
                              }}
                            >
                              {MerchantAnalysticsData.totalKYCVerified
                                ? MerchantAnalysticsData.totalKYCVerified
                                : "N/A"}
                            </Typography>
                          </Box>{" "}
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <CardMedia
                              component="img"
                              height="25px"
                              image={inactiveKYC}
                              alt="Icon"
                              sx={{
                                objectFit: "contain",
                                marginRight: "15px",
                                width: "20px",
                              }}
                            />
                            <Typography
                              sx={{
                                fontSize: "16px",
                                fontWeight: 500,
                              }}
                            >
                              {MerchantAnalysticsData.totalKYCUnverified
                                ? MerchantAnalysticsData.totalKYCUnverified
                                : "N/A"}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                      <Box>
                        <Typography
                          sx={{
                            color: "#797D8C",
                            fontSize: "12px",
                            fontWeight: 500,
                            textAlign: "left",
                          }}
                        >
                          Status:
                        </Typography>
                        <Box
                          sx={{
                            display: "grid",
                            gridTemplateRows: "1fr 1fr 1fr",
                            gridGap: "5px",
                            justifyContent: "space-between",
                            width: "100%",
                          }}
                        >
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <CardMedia
                              component="img"
                              height="25px"
                              image={activeStatus}
                              alt="Icon"
                              sx={{
                                objectFit: "contain",
                                marginRight: "15px",
                                width: "20px",
                              }}
                            />
                            <Typography
                              sx={{
                                fontSize: "16px",
                                fontWeight: 500,
                              }}
                            >
                              {MerchantAnalysticsData.totalActiveAccounts
                                ? MerchantAnalysticsData.totalActiveAccounts
                                : "N/A"}
                            </Typography>
                          </Box>
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <CardMedia
                              component="img"
                              height="25px"
                              image={inactiveStatus}
                              alt="Icon"
                              sx={{
                                objectFit: "contain",
                                marginRight: "15px",
                                width: "20px",
                              }}
                            />
                            <Typography
                              sx={{
                                fontSize: "16px",
                                fontWeight: 500,
                              }}
                            >
                              {MerchantAnalysticsData.totalInactiveAccounts
                                ? MerchantAnalysticsData.totalInactiveAccounts
                                : "N/A"}
                            </Typography>
                          </Box>{" "}
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <CardMedia
                              component="img"
                              height="25px"
                              image={suspendedStatus}
                              alt="Icon"
                              sx={{
                                objectFit: "contain",
                                marginRight: "15px",
                                width: "20px",
                              }}
                            />
                            <Typography
                              sx={{
                                fontSize: "16px",
                                fontWeight: 500,
                              }}
                            >
                              {MerchantAnalysticsData.totalSuspendedAccounts
                                ? MerchantAnalysticsData.totalSuspendedAccounts
                                : "N/A"}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                      <Box>
                        <Typography
                          sx={{
                            color: "#797D8C",
                            fontSize: "12px",
                            fontWeight: 500,
                            textAlign: "left",
                          }}
                        >
                          Verified Email:
                        </Typography>
                        <Box
                          sx={{
                            display: "grid",
                            gridTemplateRows: "1fr 1fr",
                            gridGap: "5px",
                            justifyContent: "space-between",
                            width: "100%",
                          }}
                        >
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <CardMedia
                              component="img"
                              height="25px"
                              image={verifiedEmail}
                              alt="Icon"
                              sx={{
                                objectFit: "contain",
                                marginRight: "15px",
                                width: "20px",
                              }}
                            />
                            <Typography
                              sx={{
                                fontSize: "16px",
                                fontWeight: 500,
                              }}
                            >
                              {MerchantAnalysticsData.totalVerifiedEmails
                                ? MerchantAnalysticsData.totalVerifiedEmails
                                : "N/A"}
                            </Typography>
                          </Box>{" "}
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <CardMedia
                              component="img"
                              height="25px"
                              image={unverifiedEmail}
                              alt="Icon"
                              sx={{
                                objectFit: "contain",
                                marginRight: "15px",
                                width: "20px",
                              }}
                            />
                            <Typography
                              sx={{
                                fontSize: "16px",
                                fontWeight: 500,
                              }}
                            >
                              {MerchantAnalysticsData.totalUnverifiedEmails
                                ? MerchantAnalysticsData.totalUnverifiedEmails
                                : "N/A"}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                      <Box>
                          <Typography
                            sx={{
                              color: "#797D8C",
                              fontSize: "12px",
                              fontWeight: 500,
                              textAlign: "left",
                            }}
                          >
                            Is Using 2FA:
                          </Typography>
                          <Box
                            sx={{
                              display: "grid",
                              gridTemplateRows: "1fr 1fr",
                              gridGap: "5px",
                              justifyContent: "space-between",
                              width: "100%",
                            }}
                          >
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                              <CardMedia
                                component="img"
                                height="20px"
                                image={twoFALock}
                                alt="Icon"
                                sx={{
                                  objectFit: "contain",
                                  marginRight: "15px",
                                  width: "20px",
                                }}
                              />
                              <Typography
                                sx={{
                                  fontSize: "16px",
                                  fontWeight: 500,
                                }}
                              >
                                {MerchantAnalysticsData.numberOfAccountWith2FA
                                  ? MerchantAnalysticsData.numberOfAccountWith2FA
                                  : "N/A"}
                              </Typography>
                            </Box>{" "}
                            <Box sx={{ display: "flex", alignItems: "center", position: 'relative' }}>
                              <Box sx={{ position: 'absolute', width: '25px', height: '2px', background: 'red',  transform: 'rotate(45deg)',left: '-2px' }}></Box>
                              <CardMedia
                                component="img"
                                height="20px"
                                image={twoFALock}
                                alt="Icon"
                                sx={{
                                  objectFit: "contain",
                                  marginRight: "15px",
                                  width: "20px",
                                }}
                              />
                              <Typography
                                sx={{
                                  fontSize: "16px",
                                  fontWeight: 500,
                                }}
                              >
                                {MerchantAnalysticsData.numberOfAccountWithout2FA
                                  ? MerchantAnalysticsData.numberOfAccountWithout2FA
                                  : "N/A"}
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                    </Box>
                  </Box>
                )}
              </Box>
              <MerchantOverviewTable
                data={MerchantAnalysticsData.accounts}
                value={value}
              />
            </Box>
          )}
        </TabPanel>
        {/* InActive Account List */}
        <TabPanel value={value} index={2}>
          {loading ? (
            <Box sx={{}}>
              <Skeleton
                variant="rectangular"
                sx={{
                  height: "240px",
                  backgroundColor: "#FFFFFF",
                  border: "solid 1px #E0E0E0",
                  borderRadius: "8px",
                }}
                animation="wave"
              />
            </Box>
          ) : (
            <Box>
              {/* Two Cards */}
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "repeat(2, 1fr)",
                  gridGap: "1rem",
                  margin: "0",
                }}
              >
                {/* Accounts Created */}
                {loading ? (
                  <Skeleton
                    variant="rectangular"
                    sx={{
                      height: "190px",
                      backgroundColor: "#FFFFFF",
                      border: "solid 1px #E0E0E0",
                      borderRadius: "8px",
                    }}
                    animation="wave"
                  />
                ) : (
                  <Box
                    sx={{
                      height: "100%",
                      backgroundColor: "#FFFFFF",
                      border: "solid 1px #E0E0E0",
                      borderRadius: "8px",
                      padding: "14px 25px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      gap: "10px",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        width: "100%",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "baseline",
                          width: "100%",
                        }}
                      >
                        <Typography
                          sx={{
                            color: "#797D8C",
                            fontSize: "12px",
                            fontWeight: 500,
                          }}
                        >
                          Account Created:
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          gap: "10px",
                          alignItems: "center",
                        }}
                      >
                        {" "}
                        <CardMedia
                          component="img"
                          height="20px"
                          image={MerchantIcon}
                          alt="Icon"
                          sx={{
                            objectFit: "contain",
                            marginLeft: "10px",
                            width: "20px",
                          }}
                        />
                        <Typography
                          sx={{ fontSize: "18px", fontWeight: "600" }}
                        >
                          {MerchantAnalysticsData.totalAccounts
                            ? MerchantAnalysticsData.totalAccounts
                            : "N/A"}
                        </Typography>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: "grid",
                        gridTemplateColumns: "repeat(2, 1fr)",
                        width: "100%",
                        gap: "10px",
                      }}
                    >
                      <Box>
                        <Typography
                          sx={{
                            color: "#797D8C",
                            fontSize: "12px",
                            fontWeight: 500,
                            textAlign: "left",
                          }}
                        >
                          Per Country:
                        </Typography>
                        <Box
                          sx={{
                            display: "grid",
                            gridTemplateRows: "1fr 1fr 1fr",
                            gridGap: "5px",
                            justifyContent: "space-between",
                            width: "100%",
                          }}
                        >
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <CardMedia
                              component="img"
                              height="25px"
                              image={BeninIcon}
                              alt="Icon"
                              sx={{
                                objectFit: "contain",
                                marginRight: "15px",
                                width: "20px",
                              }}
                            />
                            <Typography
                              sx={{
                                fontSize: "16px",
                                fontWeight: 500,
                              }}
                            >
                              {MerchantAnalysticsData.totalAccountsInBenin
                                ? MerchantAnalysticsData.totalAccountsInBenin
                                : "N/A"}
                            </Typography>
                          </Box>
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <CardMedia
                              component="img"
                              height="25px"
                              image={TogoIcon}
                              alt="Icon"
                              sx={{
                                objectFit: "contain",
                                marginRight: "15px",
                                width: "20px",
                              }}
                            />
                            <Typography
                              sx={{
                                fontSize: "16px",
                                fontWeight: 500,
                              }}
                            >
                              {MerchantAnalysticsData.totalAccountsInTogo
                                ? MerchantAnalysticsData.totalAccountsInTogo
                                : "N/A"}
                            </Typography>
                          </Box>{" "}
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <CardMedia
                              component="img"
                              height="25px"
                              image={CotedivoireIcon}
                              alt="Icon"
                              sx={{
                                objectFit: "contain",
                                marginRight: "15px",
                                width: "20px",
                              }}
                            />
                            <Typography
                              sx={{
                                fontSize: "16px",
                                fontWeight: 500,
                              }}
                            >
                              {MerchantAnalysticsData.totalAccountsInIvoryCoast
                                ? MerchantAnalysticsData.totalAccountsInIvoryCoast
                                : "N/A"}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                      <Box>
                        <Typography
                          sx={{
                            color: "#797D8C",
                            fontSize: "12px",
                            textAlign: "left",
                            fontWeight: 500,
                          }}
                        >
                          Business Type:
                        </Typography>
                        <Box
                          sx={{
                            display: "grid",
                            gridTemplateRows: "1fr 1fr 1fr",
                            gridGap: "5px",
                            justifyContent: "space-between",
                            width: "100%",
                          }}
                        >
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <CardMedia
                              component="img"
                              height="25px"
                              image={individualBT}
                              alt="Icon"
                              sx={{
                                objectFit: "contain",
                                marginRight: "15px",
                                width: "20px",
                              }}
                            />
                            <Typography
                              sx={{
                                fontSize: "16px",
                                fontWeight: 500,
                              }}
                            >
                              N/A
                            </Typography>
                          </Box>
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <CardMedia
                              component="img"
                              height="25px"
                              image={registeredBT}
                              alt="Icon"
                              sx={{
                                objectFit: "contain",
                                marginRight: "15px",
                                width: "20px",
                              }}
                            />
                            <Typography
                              sx={{
                                fontSize: "16px",
                                fontWeight: 500,
                              }}
                            >
                              N/A
                            </Typography>
                          </Box>{" "}
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <CardMedia
                              component="img"
                              height="25px"
                              image={noBT}
                              alt="Icon"
                              sx={{
                                objectFit: "contain",
                                marginRight: "15px",
                                width: "20px",
                              }}
                            />
                            <Typography
                              sx={{
                                fontSize: "16px",
                                fontWeight: 500,
                              }}
                            >
                              N/A
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                )}
                {/* Account Information*/}
                {loading ? (
                  <Skeleton
                    variant="rectangular"
                    sx={{
                      height: "190px",
                      backgroundColor: "#FFFFFF",
                      border: "solid 1px #E0E0E0",
                      borderRadius: "8px",
                    }}
                    animation="wave"
                  />
                ) : (
                  <Box
                    sx={{
                      height: "100%",
                      backgroundColor: "#FFFFFF",
                      border: "solid 1px #E0E0E0",
                      borderRadius: "8px",
                      padding: "14px 25px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      gap: "10px",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        width: "100%",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "baseline",
                          width: "100%",
                        }}
                      >
                        <Typography
                          sx={{
                            color: "#797D8C",
                            fontSize: "12px",
                            fontWeight: 500,
                          }}
                        >
                          Account Information:
                        </Typography>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: "grid",
                        gridTemplateColumns: "repeat(2, 1fr)",
                        width: "100%",
                        gap: "10px",
                      }}
                    >
                      <Box>
                        <Typography
                          sx={{
                            color: "#797D8C",
                            fontSize: "12px",
                            fontWeight: 500,
                            textAlign: "left",
                          }}
                        >
                          KYC:
                        </Typography>
                        <Box
                          sx={{
                            display: "grid",
                            gridTemplateRows: "1fr 1fr 1fr",
                            gridGap: "5px",
                            justifyContent: "space-between",
                            width: "100%",
                          }}
                        >
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <CardMedia
                              component="img"
                              height="25px"
                              image={activeKYC}
                              alt="Icon"
                              sx={{
                                objectFit: "contain",
                                marginRight: "15px",
                                width: "20px",
                              }}
                            />
                            <Typography
                              sx={{
                                fontSize: "16px",
                                fontWeight: 500,
                              }}
                            >
                              {MerchantAnalysticsData.totalKYCVerified
                                ? MerchantAnalysticsData.totalKYCVerified
                                : "N/A"}
                            </Typography>
                          </Box>{" "}
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <CardMedia
                              component="img"
                              height="25px"
                              image={inactiveKYC}
                              alt="Icon"
                              sx={{
                                objectFit: "contain",
                                marginRight: "15px",
                                width: "20px",
                              }}
                            />
                            <Typography
                              sx={{
                                fontSize: "16px",
                                fontWeight: 500,
                              }}
                            >
                              {MerchantAnalysticsData.totalKYCUnverified
                                ? MerchantAnalysticsData.totalKYCUnverified
                                : "N/A"}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                      <Box>
                        <Typography
                          sx={{
                            color: "#797D8C",
                            fontSize: "12px",
                            fontWeight: 500,
                            textAlign: "left",
                          }}
                        >
                          Status:
                        </Typography>
                        <Box
                          sx={{
                            display: "grid",
                            gridTemplateRows: "1fr 1fr 1fr",
                            gridGap: "5px",
                            justifyContent: "space-between",
                            width: "100%",
                          }}
                        >
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <CardMedia
                              component="img"
                              height="25px"
                              image={activeStatus}
                              alt="Icon"
                              sx={{
                                objectFit: "contain",
                                marginRight: "15px",
                                width: "20px",
                              }}
                            />
                            <Typography
                              sx={{
                                fontSize: "16px",
                                fontWeight: 500,
                              }}
                            >
                              {MerchantAnalysticsData.totalActiveAccounts
                                ? MerchantAnalysticsData.totalActiveAccounts
                                : "N/A"}
                            </Typography>
                          </Box>
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <CardMedia
                              component="img"
                              height="25px"
                              image={inactiveStatus}
                              alt="Icon"
                              sx={{
                                objectFit: "contain",
                                marginRight: "15px",
                                width: "20px",
                              }}
                            />
                            <Typography
                              sx={{
                                fontSize: "16px",
                                fontWeight: 500,
                              }}
                            >
                              {MerchantAnalysticsData.totalInactiveAccounts
                                ? MerchantAnalysticsData.totalInactiveAccounts
                                : "N/A"}
                            </Typography>
                          </Box>{" "}
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <CardMedia
                              component="img"
                              height="25px"
                              image={suspendedStatus}
                              alt="Icon"
                              sx={{
                                objectFit: "contain",
                                marginRight: "15px",
                                width: "20px",
                              }}
                            />
                            <Typography
                              sx={{
                                fontSize: "16px",
                                fontWeight: 500,
                              }}
                            >
                              {MerchantAnalysticsData.totalSuspendedAccounts
                                ? MerchantAnalysticsData.totalSuspendedAccounts
                                : "N/A"}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                      <Box>
                        <Typography
                          sx={{
                            color: "#797D8C",
                            fontSize: "12px",
                            fontWeight: 500,
                            textAlign: "left",
                          }}
                        >
                          Verified Email:
                        </Typography>
                        <Box
                          sx={{
                            display: "grid",
                            gridTemplateRows: "1fr 1fr",
                            gridGap: "5px",
                            justifyContent: "space-between",
                            width: "100%",
                          }}
                        >
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <CardMedia
                              component="img"
                              height="25px"
                              image={verifiedEmail}
                              alt="Icon"
                              sx={{
                                objectFit: "contain",
                                marginRight: "15px",
                                width: "20px",
                              }}
                            />
                            <Typography
                              sx={{
                                fontSize: "16px",
                                fontWeight: 500,
                              }}
                            >
                              {MerchantAnalysticsData.totalVerifiedEmails
                                ? MerchantAnalysticsData.totalVerifiedEmails
                                : "N/A"}
                            </Typography>
                          </Box>{" "}
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <CardMedia
                              component="img"
                              height="25px"
                              image={unverifiedEmail}
                              alt="Icon"
                              sx={{
                                objectFit: "contain",
                                marginRight: "15px",
                                width: "20px",
                              }}
                            />
                            <Typography
                              sx={{
                                fontSize: "16px",
                                fontWeight: 500,
                              }}
                            >
                              {MerchantAnalysticsData.totalUnverifiedEmails
                                ? MerchantAnalysticsData.totalUnverifiedEmails
                                : "N/A"}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                      <Box>
                          <Typography
                            sx={{
                              color: "#797D8C",
                              fontSize: "12px",
                              fontWeight: 500,
                              textAlign: "left",
                            }}
                          >
                            Is Using 2FA:
                          </Typography>
                          <Box
                            sx={{
                              display: "grid",
                              gridTemplateRows: "1fr 1fr",
                              gridGap: "5px",
                              justifyContent: "space-between",
                              width: "100%",
                            }}
                          >
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                              <CardMedia
                                component="img"
                                height="20px"
                                image={twoFALock}
                                alt="Icon"
                                sx={{
                                  objectFit: "contain",
                                  marginRight: "15px",
                                  width: "20px",
                                }}
                              />
                              <Typography
                                sx={{
                                  fontSize: "16px",
                                  fontWeight: 500,
                                }}
                              >
                                {MerchantAnalysticsData.numberOfAccountWith2FA
                                  ? MerchantAnalysticsData.numberOfAccountWith2FA
                                  : "N/A"}
                              </Typography>
                            </Box>{" "}
                            <Box sx={{ display: "flex", alignItems: "center", position: 'relative' }}>
                              <Box sx={{ position: 'absolute', width: '25px', height: '2px', background: 'red',  transform: 'rotate(45deg)',left: '-2px' }}></Box>
                              <CardMedia
                                component="img"
                                height="20px"
                                image={twoFALock}
                                alt="Icon"
                                sx={{
                                  objectFit: "contain",
                                  marginRight: "15px",
                                  width: "20px",
                                }}
                              />
                              <Typography
                                sx={{
                                  fontSize: "16px",
                                  fontWeight: 500,
                                }}
                              >
                                {MerchantAnalysticsData.numberOfAccountWithout2FA
                                  ? MerchantAnalysticsData.numberOfAccountWithout2FA
                                  : "N/A"}
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                    </Box>
                  </Box>
                )}
              </Box>
              <MerchantOverviewTable
                data={MerchantAnalysticsData.accounts}
                value={value}
              />
            </Box>
          )}
        </TabPanel>
        <TabPanel value={value} index={3}>
          {loading ? (
            <Box sx={{}}>
              <Skeleton
                variant="rectangular"
                sx={{
                  height: "240px",
                  backgroundColor: "#FFFFFF",
                  border: "solid 1px #E0E0E0",
                  borderRadius: "8px",
                }}
                animation="wave"
              />
            </Box>
          ) : (
            <Box>
              {/* Two Cards */}
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "repeat(2, 1fr)",
                  gridGap: "1rem",
                  margin: "0",
                }}
              >
                {/* Accounts Created */}
                {loading ? (
                  <Skeleton
                    variant="rectangular"
                    sx={{
                      height: "190px",
                      backgroundColor: "#FFFFFF",
                      border: "solid 1px #E0E0E0",
                      borderRadius: "8px",
                    }}
                    animation="wave"
                  />
                ) : (
                  <Box
                    sx={{
                      height: "100%",
                      backgroundColor: "#FFFFFF",
                      border: "solid 1px #E0E0E0",
                      borderRadius: "8px",
                      padding: "14px 25px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      gap: "10px",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        width: "100%",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "baseline",
                          width: "100%",
                        }}
                      >
                        <Typography
                          sx={{
                            color: "#797D8C",
                            fontSize: "12px",
                            fontWeight: 500,
                          }}
                        >
                          Account Created:
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          gap: "10px",
                          alignItems: "center",
                        }}
                      >
                        {" "}
                        <CardMedia
                          component="img"
                          height="20px"
                          image={MerchantIcon}
                          alt="Icon"
                          sx={{
                            objectFit: "contain",
                            marginLeft: "10px",
                            width: "20px",
                          }}
                        />
                        <Typography
                          sx={{ fontSize: "18px", fontWeight: "600" }}
                        >
                          {MerchantAnalysticsData.totalAccounts
                            ? MerchantAnalysticsData.totalAccounts
                            : "N/A"}
                        </Typography>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: "grid",
                        gridTemplateColumns: "repeat(2, 1fr)",
                        width: "100%",
                        gap: "10px",
                      }}
                    >
                      <Box>
                        <Typography
                          sx={{
                            color: "#797D8C",
                            fontSize: "12px",
                            fontWeight: 500,
                            textAlign: "left",
                          }}
                        >
                          Per Country:
                        </Typography>
                        <Box
                          sx={{
                            display: "grid",
                            gridTemplateRows: "1fr 1fr 1fr",
                            gridGap: "5px",
                            justifyContent: "space-between",
                            width: "100%",
                          }}
                        >
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <CardMedia
                              component="img"
                              height="25px"
                              image={BeninIcon}
                              alt="Icon"
                              sx={{
                                objectFit: "contain",
                                marginRight: "15px",
                                width: "20px",
                              }}
                            />
                            <Typography
                              sx={{
                                fontSize: "16px",
                                fontWeight: 500,
                              }}
                            >
                              {MerchantAnalysticsData.totalAccountsInBenin
                                ? MerchantAnalysticsData.totalAccountsInBenin
                                : "N/A"}
                            </Typography>
                          </Box>
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <CardMedia
                              component="img"
                              height="25px"
                              image={TogoIcon}
                              alt="Icon"
                              sx={{
                                objectFit: "contain",
                                marginRight: "15px",
                                width: "20px",
                              }}
                            />
                            <Typography
                              sx={{
                                fontSize: "16px",
                                fontWeight: 500,
                              }}
                            >
                              {MerchantAnalysticsData.totalAccountsInTogo
                                ? MerchantAnalysticsData.totalAccountsInTogo
                                : "N/A"}
                            </Typography>
                          </Box>{" "}
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <CardMedia
                              component="img"
                              height="25px"
                              image={CotedivoireIcon}
                              alt="Icon"
                              sx={{
                                objectFit: "contain",
                                marginRight: "15px",
                                width: "20px",
                              }}
                            />
                            <Typography
                              sx={{
                                fontSize: "16px",
                                fontWeight: 500,
                              }}
                            >
                              {MerchantAnalysticsData.totalAccountsInIvoryCoast
                                ? MerchantAnalysticsData.totalAccountsInIvoryCoast
                                : "N/A"}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                      <Box>
                        <Typography
                          sx={{
                            color: "#797D8C",
                            fontSize: "12px",
                            textAlign: "left",
                            fontWeight: 500,
                          }}
                        >
                          Business Type:
                        </Typography>
                        <Box
                          sx={{
                            display: "grid",
                            gridTemplateRows: "1fr 1fr 1fr",
                            gridGap: "5px",
                            justifyContent: "space-between",
                            width: "100%",
                          }}
                        >
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <CardMedia
                              component="img"
                              height="25px"
                              image={individualBT}
                              alt="Icon"
                              sx={{
                                objectFit: "contain",
                                marginRight: "15px",
                                width: "20px",
                              }}
                            />
                            <Typography
                              sx={{
                                fontSize: "16px",
                                fontWeight: 500,
                              }}
                            >
                              N/A
                            </Typography>
                          </Box>
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <CardMedia
                              component="img"
                              height="25px"
                              image={registeredBT}
                              alt="Icon"
                              sx={{
                                objectFit: "contain",
                                marginRight: "15px",
                                width: "20px",
                              }}
                            />
                            <Typography
                              sx={{
                                fontSize: "16px",
                                fontWeight: 500,
                              }}
                            >
                              N/A
                            </Typography>
                          </Box>{" "}
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <CardMedia
                              component="img"
                              height="25px"
                              image={noBT}
                              alt="Icon"
                              sx={{
                                objectFit: "contain",
                                marginRight: "15px",
                                width: "20px",
                              }}
                            />
                            <Typography
                              sx={{
                                fontSize: "16px",
                                fontWeight: 500,
                              }}
                            >
                              N/A
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                )}
                {/* Account Information*/}
                {loading ? (
                  <Skeleton
                    variant="rectangular"
                    sx={{
                      height: "190px",
                      backgroundColor: "#FFFFFF",
                      border: "solid 1px #E0E0E0",
                      borderRadius: "8px",
                    }}
                    animation="wave"
                  />
                ) : (
                  <Box
                    sx={{
                      height: "100%",
                      backgroundColor: "#FFFFFF",
                      border: "solid 1px #E0E0E0",
                      borderRadius: "8px",
                      padding: "14px 25px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      gap: "10px",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        width: "100%",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "baseline",
                          width: "100%",
                        }}
                      >
                        <Typography
                          sx={{
                            color: "#797D8C",
                            fontSize: "12px",
                            fontWeight: 500,
                          }}
                        >
                          Account Information:
                        </Typography>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: "grid",
                        gridTemplateColumns: "repeat(2, 1fr)",
                        width: "100%",
                        gap: "10px",
                      }}
                    >
                      <Box>
                        <Typography
                          sx={{
                            color: "#797D8C",
                            fontSize: "12px",
                            fontWeight: 500,
                            textAlign: "left",
                          }}
                        >
                          KYC:
                        </Typography>
                        <Box
                          sx={{
                            display: "grid",
                            gridTemplateRows: "1fr 1fr 1fr",
                            gridGap: "5px",
                            justifyContent: "space-between",
                            width: "100%",
                          }}
                        >
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <CardMedia
                              component="img"
                              height="25px"
                              image={activeKYC}
                              alt="Icon"
                              sx={{
                                objectFit: "contain",
                                marginRight: "15px",
                                width: "20px",
                              }}
                            />
                            <Typography
                              sx={{
                                fontSize: "16px",
                                fontWeight: 500,
                              }}
                            >
                              {MerchantAnalysticsData.totalKYCVerified
                                ? MerchantAnalysticsData.totalKYCVerified
                                : "N/A"}
                            </Typography>
                          </Box>{" "}
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <CardMedia
                              component="img"
                              height="25px"
                              image={inactiveKYC}
                              alt="Icon"
                              sx={{
                                objectFit: "contain",
                                marginRight: "15px",
                                width: "20px",
                              }}
                            />
                            <Typography
                              sx={{
                                fontSize: "16px",
                                fontWeight: 500,
                              }}
                            >
                              {MerchantAnalysticsData.totalKYCUnverified
                                ? MerchantAnalysticsData.totalKYCUnverified
                                : "N/A"}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                      <Box>
                        <Typography
                          sx={{
                            color: "#797D8C",
                            fontSize: "12px",
                            fontWeight: 500,
                            textAlign: "left",
                          }}
                        >
                          Status:
                        </Typography>
                        <Box
                          sx={{
                            display: "grid",
                            gridTemplateRows: "1fr 1fr 1fr",
                            gridGap: "5px",
                            justifyContent: "space-between",
                            width: "100%",
                          }}
                        >
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <CardMedia
                              component="img"
                              height="25px"
                              image={activeStatus}
                              alt="Icon"
                              sx={{
                                objectFit: "contain",
                                marginRight: "15px",
                                width: "20px",
                              }}
                            />
                            <Typography
                              sx={{
                                fontSize: "16px",
                                fontWeight: 500,
                              }}
                            >
                              {MerchantAnalysticsData.totalActiveAccounts
                                ? MerchantAnalysticsData.totalActiveAccounts
                                : "N/A"}
                            </Typography>
                          </Box>
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <CardMedia
                              component="img"
                              height="25px"
                              image={inactiveStatus}
                              alt="Icon"
                              sx={{
                                objectFit: "contain",
                                marginRight: "15px",
                                width: "20px",
                              }}
                            />
                            <Typography
                              sx={{
                                fontSize: "16px",
                                fontWeight: 500,
                              }}
                            >
                              {MerchantAnalysticsData.totalInactiveAccounts
                                ? MerchantAnalysticsData.totalInactiveAccounts
                                : "N/A"}
                            </Typography>
                          </Box>{" "}
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <CardMedia
                              component="img"
                              height="25px"
                              image={suspendedStatus}
                              alt="Icon"
                              sx={{
                                objectFit: "contain",
                                marginRight: "15px",
                                width: "20px",
                              }}
                            />
                            <Typography
                              sx={{
                                fontSize: "16px",
                                fontWeight: 500,
                              }}
                            >
                              {MerchantAnalysticsData.totalSuspendedAccounts
                                ? MerchantAnalysticsData.totalSuspendedAccounts
                                : "N/A"}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                      <Box>
                        <Typography
                          sx={{
                            color: "#797D8C",
                            fontSize: "12px",
                            fontWeight: 500,
                            textAlign: "left",
                          }}
                        >
                          Verified Email:
                        </Typography>
                        <Box
                          sx={{
                            display: "grid",
                            gridTemplateRows: "1fr 1fr",
                            gridGap: "5px",
                            justifyContent: "space-between",
                            width: "100%",
                          }}
                        >
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <CardMedia
                              component="img"
                              height="25px"
                              image={verifiedEmail}
                              alt="Icon"
                              sx={{
                                objectFit: "contain",
                                marginRight: "15px",
                                width: "20px",
                              }}
                            />
                            <Typography
                              sx={{
                                fontSize: "16px",
                                fontWeight: 500,
                              }}
                            >
                              {MerchantAnalysticsData.totalVerifiedEmails
                                ? MerchantAnalysticsData.totalVerifiedEmails
                                : "N/A"}
                            </Typography>
                          </Box>{" "}
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <CardMedia
                              component="img"
                              height="25px"
                              image={unverifiedEmail}
                              alt="Icon"
                              sx={{
                                objectFit: "contain",
                                marginRight: "15px",
                                width: "20px",
                              }}
                            />
                            <Typography
                              sx={{
                                fontSize: "16px",
                                fontWeight: 500,
                              }}
                            >
                              {MerchantAnalysticsData.totalUnverifiedEmails
                                ? MerchantAnalysticsData.totalUnverifiedEmails
                                : "N/A"}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                      <Box>
                          <Typography
                            sx={{
                              color: "#797D8C",
                              fontSize: "12px",
                              fontWeight: 500,
                              textAlign: "left",
                            }}
                          >
                            Is Using 2FA:
                          </Typography>
                          <Box
                            sx={{
                              display: "grid",
                              gridTemplateRows: "1fr 1fr",
                              gridGap: "5px",
                              justifyContent: "space-between",
                              width: "100%",
                            }}
                          >
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                              <CardMedia
                                component="img"
                                height="20px"
                                image={twoFALock}
                                alt="Icon"
                                sx={{
                                  objectFit: "contain",
                                  marginRight: "15px",
                                  width: "20px",
                                }}
                              />
                              <Typography
                                sx={{
                                  fontSize: "16px",
                                  fontWeight: 500,
                                }}
                              >
                                {MerchantAnalysticsData.numberOfAccountWith2FA
                                  ? MerchantAnalysticsData.numberOfAccountWith2FA
                                  : "N/A"}
                              </Typography>
                            </Box>{" "}
                            <Box sx={{ display: "flex", alignItems: "center", position: 'relative' }}>
                              <Box sx={{ position: 'absolute', width: '25px', height: '2px', background: 'red',  transform: 'rotate(45deg)',left: '-2px' }}></Box>
                              <CardMedia
                                component="img"
                                height="20px"
                                image={twoFALock}
                                alt="Icon"
                                sx={{
                                  objectFit: "contain",
                                  marginRight: "15px",
                                  width: "20px",
                                }}
                              />
                              <Typography
                                sx={{
                                  fontSize: "16px",
                                  fontWeight: 500,
                                }}
                              >
                                {MerchantAnalysticsData.numberOfAccountWithout2FA
                                  ? MerchantAnalysticsData.numberOfAccountWithout2FA
                                  : "N/A"}
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                    </Box>
                  </Box>
                )}
              </Box>
              <MerchantOverviewTable
                data={MerchantAnalysticsData.accounts}
                value={value}
              />
            </Box>
          )}
        </TabPanel>
        <TabPanel value={value} index={4}>
          {loading ? (
            <Box sx={{}}>
              <Skeleton
                variant="rectangular"
                sx={{
                  height: "240px",
                  backgroundColor: "#FFFFFF",
                  border: "solid 1px #E0E0E0",
                  borderRadius: "8px",
                }}
                animation="wave"
              />
            </Box>
          ) : (
            <Box>
              {/* Two Cards */}
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "repeat(2, 1fr)",
                  gridGap: "1rem",
                  margin: "0",
                }}
              >
                {/* Accounts Created */}
                {loading ? (
                  <Skeleton
                    variant="rectangular"
                    sx={{
                      height: "190px",
                      backgroundColor: "#FFFFFF",
                      border: "solid 1px #E0E0E0",
                      borderRadius: "8px",
                    }}
                    animation="wave"
                  />
                ) : (
                  <Box
                    sx={{
                      height: "100%",
                      backgroundColor: "#FFFFFF",
                      border: "solid 1px #E0E0E0",
                      borderRadius: "8px",
                      padding: "14px 25px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      gap: "10px",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        width: "100%",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "baseline",
                          width: "100%",
                        }}
                      >
                        <Typography
                          sx={{
                            color: "#797D8C",
                            fontSize: "12px",
                            fontWeight: 500,
                          }}
                        >
                          Account Created:
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          gap: "10px",
                          alignItems: "center",
                        }}
                      >
                        {" "}
                        <CardMedia
                          component="img"
                          height="20px"
                          image={MerchantIcon}
                          alt="Icon"
                          sx={{
                            objectFit: "contain",
                            marginLeft: "10px",
                            width: "20px",
                          }}
                        />
                        <Typography
                          sx={{ fontSize: "18px", fontWeight: "600" }}
                        >
                          {MerchantAnalysticsData.totalAccounts
                            ? MerchantAnalysticsData.totalAccounts
                            : "N/A"}
                        </Typography>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: "grid",
                        gridTemplateColumns: "repeat(2, 1fr)",
                        width: "100%",
                        gap: "10px",
                      }}
                    >
                      <Box>
                        <Typography
                          sx={{
                            color: "#797D8C",
                            fontSize: "12px",
                            fontWeight: 500,
                            textAlign: "left",
                          }}
                        >
                          Per Country:
                        </Typography>
                        <Box
                          sx={{
                            display: "grid",
                            gridTemplateRows: "1fr 1fr 1fr",
                            gridGap: "5px",
                            justifyContent: "space-between",
                            width: "100%",
                          }}
                        >
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <CardMedia
                              component="img"
                              height="25px"
                              image={BeninIcon}
                              alt="Icon"
                              sx={{
                                objectFit: "contain",
                                marginRight: "15px",
                                width: "20px",
                              }}
                            />
                            <Typography
                              sx={{
                                fontSize: "16px",
                                fontWeight: 500,
                              }}
                            >
                              {MerchantAnalysticsData.totalAccountsInBenin
                                ? MerchantAnalysticsData.totalAccountsInBenin
                                : "N/A"}
                            </Typography>
                          </Box>
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <CardMedia
                              component="img"
                              height="25px"
                              image={TogoIcon}
                              alt="Icon"
                              sx={{
                                objectFit: "contain",
                                marginRight: "15px",
                                width: "20px",
                              }}
                            />
                            <Typography
                              sx={{
                                fontSize: "16px",
                                fontWeight: 500,
                              }}
                            >
                              {MerchantAnalysticsData.totalAccountsInTogo
                                ? MerchantAnalysticsData.totalAccountsInTogo
                                : "N/A"}
                            </Typography>
                          </Box>{" "}
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <CardMedia
                              component="img"
                              height="25px"
                              image={CotedivoireIcon}
                              alt="Icon"
                              sx={{
                                objectFit: "contain",
                                marginRight: "15px",
                                width: "20px",
                              }}
                            />
                            <Typography
                              sx={{
                                fontSize: "16px",
                                fontWeight: 500,
                              }}
                            >
                              {MerchantAnalysticsData.totalAccountsInIvoryCoast
                                ? MerchantAnalysticsData.totalAccountsInIvoryCoast
                                : "N/A"}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                      <Box>
                        <Typography
                          sx={{
                            color: "#797D8C",
                            fontSize: "12px",
                            textAlign: "left",
                            fontWeight: 500,
                          }}
                        >
                          Business Type:
                        </Typography>
                        <Box
                          sx={{
                            display: "grid",
                            gridTemplateRows: "1fr 1fr 1fr",
                            gridGap: "5px",
                            justifyContent: "space-between",
                            width: "100%",
                          }}
                        >
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <CardMedia
                              component="img"
                              height="25px"
                              image={individualBT}
                              alt="Icon"
                              sx={{
                                objectFit: "contain",
                                marginRight: "15px",
                                width: "20px",
                              }}
                            />
                            <Typography
                              sx={{
                                fontSize: "16px",
                                fontWeight: 500,
                              }}
                            >
                              N/A
                            </Typography>
                          </Box>
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <CardMedia
                              component="img"
                              height="25px"
                              image={registeredBT}
                              alt="Icon"
                              sx={{
                                objectFit: "contain",
                                marginRight: "15px",
                                width: "20px",
                              }}
                            />
                            <Typography
                              sx={{
                                fontSize: "16px",
                                fontWeight: 500,
                              }}
                            >
                              N/A
                            </Typography>
                          </Box>{" "}
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <CardMedia
                              component="img"
                              height="25px"
                              image={noBT}
                              alt="Icon"
                              sx={{
                                objectFit: "contain",
                                marginRight: "15px",
                                width: "20px",
                              }}
                            />
                            <Typography
                              sx={{
                                fontSize: "16px",
                                fontWeight: 500,
                              }}
                            >
                              N/A
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                )}
                {/* Account Information*/}
                {loading ? (
                  <Skeleton
                    variant="rectangular"
                    sx={{
                      height: "190px",
                      backgroundColor: "#FFFFFF",
                      border: "solid 1px #E0E0E0",
                      borderRadius: "8px",
                    }}
                    animation="wave"
                  />
                ) : (
                  <Box
                    sx={{
                      height: "100%",
                      backgroundColor: "#FFFFFF",
                      border: "solid 1px #E0E0E0",
                      borderRadius: "8px",
                      padding: "14px 25px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      gap: "10px",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        width: "100%",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "baseline",
                          width: "100%",
                        }}
                      >
                        <Typography
                          sx={{
                            color: "#797D8C",
                            fontSize: "12px",
                            fontWeight: 500,
                          }}
                        >
                          Account Information:
                        </Typography>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: "grid",
                        gridTemplateColumns: "repeat(2, 1fr)",
                        width: "100%",
                        gap: "10px",
                      }}
                    >
                      <Box>
                        <Typography
                          sx={{
                            color: "#797D8C",
                            fontSize: "12px",
                            fontWeight: 500,
                            textAlign: "left",
                          }}
                        >
                          KYC:
                        </Typography>
                        <Box
                          sx={{
                            display: "grid",
                            gridTemplateRows: "1fr 1fr 1fr",
                            gridGap: "5px",
                            justifyContent: "space-between",
                            width: "100%",
                          }}
                        >
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <CardMedia
                              component="img"
                              height="25px"
                              image={activeKYC}
                              alt="Icon"
                              sx={{
                                objectFit: "contain",
                                marginRight: "15px",
                                width: "20px",
                              }}
                            />
                            <Typography
                              sx={{
                                fontSize: "16px",
                                fontWeight: 500,
                              }}
                            >
                              {MerchantAnalysticsData.totalKYCVerified
                                ? MerchantAnalysticsData.totalKYCVerified
                                : "N/A"}
                            </Typography>
                          </Box>{" "}
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <CardMedia
                              component="img"
                              height="25px"
                              image={inactiveKYC}
                              alt="Icon"
                              sx={{
                                objectFit: "contain",
                                marginRight: "15px",
                                width: "20px",
                              }}
                            />
                            <Typography
                              sx={{
                                fontSize: "16px",
                                fontWeight: 500,
                              }}
                            >
                              {MerchantAnalysticsData.totalKYCUnverified
                                ? MerchantAnalysticsData.totalKYCUnverified
                                : "N/A"}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                      <Box>
                        <Typography
                          sx={{
                            color: "#797D8C",
                            fontSize: "12px",
                            fontWeight: 500,
                            textAlign: "left",
                          }}
                        >
                          Status:
                        </Typography>
                        <Box
                          sx={{
                            display: "grid",
                            gridTemplateRows: "1fr 1fr 1fr",
                            gridGap: "5px",
                            justifyContent: "space-between",
                            width: "100%",
                          }}
                        >
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <CardMedia
                              component="img"
                              height="25px"
                              image={activeStatus}
                              alt="Icon"
                              sx={{
                                objectFit: "contain",
                                marginRight: "15px",
                                width: "20px",
                              }}
                            />
                            <Typography
                              sx={{
                                fontSize: "16px",
                                fontWeight: 500,
                              }}
                            >
                              {MerchantAnalysticsData.totalActiveAccounts
                                ? MerchantAnalysticsData.totalActiveAccounts
                                : "N/A"}
                            </Typography>
                          </Box>
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <CardMedia
                              component="img"
                              height="25px"
                              image={inactiveStatus}
                              alt="Icon"
                              sx={{
                                objectFit: "contain",
                                marginRight: "15px",
                                width: "20px",
                              }}
                            />
                            <Typography
                              sx={{
                                fontSize: "16px",
                                fontWeight: 500,
                              }}
                            >
                              {MerchantAnalysticsData.totalInactiveAccounts
                                ? MerchantAnalysticsData.totalInactiveAccounts
                                : "N/A"}
                            </Typography>
                          </Box>{" "}
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <CardMedia
                              component="img"
                              height="25px"
                              image={suspendedStatus}
                              alt="Icon"
                              sx={{
                                objectFit: "contain",
                                marginRight: "15px",
                                width: "20px",
                              }}
                            />
                            <Typography
                              sx={{
                                fontSize: "16px",
                                fontWeight: 500,
                              }}
                            >
                              {MerchantAnalysticsData.totalSuspendedAccounts
                                ? MerchantAnalysticsData.totalSuspendedAccounts
                                : "N/A"}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                      <Box>
                        <Typography
                          sx={{
                            color: "#797D8C",
                            fontSize: "12px",
                            fontWeight: 500,
                            textAlign: "left",
                          }}
                        >
                          Verified Email:
                        </Typography>
                        <Box
                          sx={{
                            display: "grid",
                            gridTemplateRows: "1fr 1fr",
                            gridGap: "5px",
                            justifyContent: "space-between",
                            width: "100%",
                          }}
                        >
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <CardMedia
                              component="img"
                              height="25px"
                              image={verifiedEmail}
                              alt="Icon"
                              sx={{
                                objectFit: "contain",
                                marginRight: "15px",
                                width: "20px",
                              }}
                            />
                            <Typography
                              sx={{
                                fontSize: "16px",
                                fontWeight: 500,
                              }}
                            >
                              {MerchantAnalysticsData.totalVerifiedEmails
                                ? MerchantAnalysticsData.totalVerifiedEmails
                                : "N/A"}
                            </Typography>
                          </Box>{" "}
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <CardMedia
                              component="img"
                              height="25px"
                              image={unverifiedEmail}
                              alt="Icon"
                              sx={{
                                objectFit: "contain",
                                marginRight: "15px",
                                width: "20px",
                              }}
                            />
                            <Typography
                              sx={{
                                fontSize: "16px",
                                fontWeight: 500,
                              }}
                            >
                              {MerchantAnalysticsData.totalUnverifiedEmails
                                ? MerchantAnalysticsData.totalUnverifiedEmails
                                : "N/A"}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>

                      <Box>
                          <Typography
                            sx={{
                              color: "#797D8C",
                              fontSize: "12px",
                              fontWeight: 500,
                              textAlign: "left",
                            }}
                          >
                            Is Using 2FA:
                          </Typography>
                          <Box
                            sx={{
                              display: "grid",
                              gridTemplateRows: "1fr 1fr",
                              gridGap: "5px",
                              justifyContent: "space-between",
                              width: "100%",
                            }}
                          >
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                              <CardMedia
                                component="img"
                                height="20px"
                                image={twoFALock}
                                alt="Icon"
                                sx={{
                                  objectFit: "contain",
                                  marginRight: "15px",
                                  width: "20px",
                                }}
                              />
                              <Typography
                                sx={{
                                  fontSize: "16px",
                                  fontWeight: 500,
                                }}
                              >
                                {MerchantAnalysticsData.numberOfAccountWith2FA
                                  ? MerchantAnalysticsData.numberOfAccountWith2FA
                                  : "N/A"}
                              </Typography>
                            </Box>{" "}
                            <Box sx={{ display: "flex", alignItems: "center", position: 'relative' }}>
                              <Box sx={{ position: 'absolute', width: '25px', height: '2px', background: 'red',  transform: 'rotate(45deg)',left: '-2px' }}></Box>
                              <CardMedia
                                component="img"
                                height="20px"
                                image={twoFALock}
                                alt="Icon"
                                sx={{
                                  objectFit: "contain",
                                  marginRight: "15px",
                                  width: "20px",
                                }}
                              />
                              <Typography
                                sx={{
                                  fontSize: "16px",
                                  fontWeight: 500,
                                }}
                              >
                                {MerchantAnalysticsData.numberOfAccountWithout2FA
                                  ? MerchantAnalysticsData.numberOfAccountWithout2FA
                                  : "N/A"}
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                    </Box>
                  </Box>
                )}
              </Box>
              <MerchantOverviewTable
                data={MerchantAnalysticsData.accounts}
                value={value}
              />
            </Box>
          )}
        </TabPanel>
        <TabPanel value={value} index={5}>
          {/* CUSTOM DATE */} 
          <Box sx={{   display: "grid",
                    gridTemplateColumns: "repeat(2, 1fr)",
                    gridGap: "1rem",}}>
            <Box sx={{display: 'flex', flexDirection: 'column',width: '100%', backgroundColor: "#FFFFFF",
                    border: "solid 1px #E0E0E0",
                    borderRadius: "8px",padding: "14px 25px", marginBottom: '20px'}}>
              <Typography
                sx={{
                  color: "#797D8C",
                  fontSize: "12px",
                  fontWeight: 500,
                  textAlign: 'left'
                }}
              >
                Choose Custom Date:
              </Typography>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  // justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  gap: "20px",
                }}
              >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      position: "relative",width: '100%'
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                        width: '100%'
                      }}
                    >
                      <DemoContainer components={["DatePicker"]}>
                        <DatePicker
                          label="Start Date"
                          value={startDate}
                          onChange={handleStartDateChange}
                          sx={{width: '100%'}}
                        />
                      </DemoContainer>
                      <Box sx={{ width: "15px", border: "#000 solid 1px" }} />
                      <DemoContainer components={["DatePicker"]}>
                        <DatePicker
                          label="End Date"
                          value={endDate}
                          onChange={handleEndDateChange}
                          sx={{width: '100%'}}
                        />
                      </DemoContainer>
                    </Box>
                  </Box>
                </LocalizationProvider>
                <Button
                    sx={{
                      height: "55px",
                      backgroundColor: "#F59E0B",
                      "&:hover": {
                        backgroundColor: "#E18D00", // Change this to your desired hover color
                      },
                    }}
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={handleCustomDate}
                  >
                    Search
                  </Button> 
              </Box>
            </Box></Box>
          <Box>
            {loading ? (
              <Box sx={{}}>
                <Skeleton
                  variant="rectangular"
                  sx={{
                    height: "240px",
                    backgroundColor: "#FFFFFF",
                    border: "solid 1px #E0E0E0",
                    borderRadius: "8px",
                  }}
                  animation="wave"
                />
              </Box>
            ) : (
              <Box>
                {/* Two Cards */}
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "repeat(2, 1fr)",
                    gridGap: "1rem",
                    margin: "0",
                  }}
                >
                  {/* Accounts Created */}
                  {loading ? (
                    <Skeleton
                      variant="rectangular"
                      sx={{
                        height: "190px",
                        backgroundColor: "#FFFFFF",
                        border: "solid 1px #E0E0E0",
                        borderRadius: "8px",
                      }}
                      animation="wave"
                    />
                  ) : (
                    <Box
                      sx={{
                        height: "100%",
                        backgroundColor: "#FFFFFF",
                        border: "solid 1px #E0E0E0",
                        borderRadius: "8px",
                        padding: "14px 25px",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        gap: "10px",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                          width: "100%",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "baseline",
                            width: "100%",
                          }}
                        >
                          <Typography
                            sx={{
                              color: "#797D8C",
                              fontSize: "12px",
                              fontWeight: 500,
                            }}
                          >
                            Account Created:
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            gap: "10px",
                            alignItems: "center",
                          }}
                        >
                          {" "}
                          <CardMedia
                            component="img"
                            height="20px"
                            image={MerchantIcon}
                            alt="Icon"
                            sx={{
                              objectFit: "contain",
                              marginLeft: "10px",
                              width: "20px",
                            }}
                          />
                          <Typography
                            sx={{ fontSize: "18px", fontWeight: "600" }}
                          >
                            {MerchantAnalysticsData.totalAccounts
                              ? MerchantAnalysticsData.totalAccounts
                              : "N/A"}
                          </Typography>
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          display: "grid",
                          gridTemplateColumns: "repeat(2, 1fr)",
                          width: "100%",
                          gap: "10px",
                        }}
                      >
                        <Box>
                          <Typography
                            sx={{
                              color: "#797D8C",
                              fontSize: "12px",
                              fontWeight: 500,
                              textAlign: "left",
                            }}
                          >
                            Per Country:
                          </Typography>
                          <Box
                            sx={{
                              display: "grid",
                              gridTemplateRows: "1fr 1fr 1fr",
                              gridGap: "5px",
                              justifyContent: "space-between",
                              width: "100%",
                            }}
                          >
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                              <CardMedia
                                component="img"
                                height="25px"
                                image={BeninIcon}
                                alt="Icon"
                                sx={{
                                  objectFit: "contain",
                                  marginRight: "15px",
                                  width: "20px",
                                }}
                              />
                              <Typography
                                sx={{
                                  fontSize: "16px",
                                  fontWeight: 500,
                                }}
                              >
                                {MerchantAnalysticsData.totalAccountsInBenin
                                  ? MerchantAnalysticsData.totalAccountsInBenin
                                  : "N/A"}
                              </Typography>
                            </Box>
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                              <CardMedia
                                component="img"
                                height="25px"
                                image={TogoIcon}
                                alt="Icon"
                                sx={{
                                  objectFit: "contain",
                                  marginRight: "15px",
                                  width: "20px",
                                }}
                              />
                              <Typography
                                sx={{
                                  fontSize: "16px",
                                  fontWeight: 500,
                                }}
                              >
                                {MerchantAnalysticsData.totalAccountsInTogo
                                  ? MerchantAnalysticsData.totalAccountsInTogo
                                  : "N/A"}
                              </Typography>
                            </Box>{" "}
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                              <CardMedia
                                component="img"
                                height="25px"
                                image={CotedivoireIcon}
                                alt="Icon"
                                sx={{
                                  objectFit: "contain",
                                  marginRight: "15px",
                                  width: "20px",
                                }}
                              />
                              <Typography
                                sx={{
                                  fontSize: "16px",
                                  fontWeight: 500,
                                }}
                              >
                                {MerchantAnalysticsData.totalAccountsInIvoryCoast
                                  ? MerchantAnalysticsData.totalAccountsInIvoryCoast
                                  : "N/A"}
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                        <Box>
                          <Typography
                            sx={{
                              color: "#797D8C",
                              fontSize: "12px",
                              textAlign: "left",
                              fontWeight: 500,
                            }}
                          >
                            Business Type:
                          </Typography>
                          <Box
                            sx={{
                              display: "grid",
                              gridTemplateRows: "1fr 1fr 1fr",
                              gridGap: "5px",
                              justifyContent: "space-between",
                              width: "100%",
                            }}
                          >
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                              <CardMedia
                                component="img"
                                height="25px"
                                image={individualBT}
                                alt="Icon"
                                sx={{
                                  objectFit: "contain",
                                  marginRight: "15px",
                                  width: "20px",
                                }}
                              />
                              <Typography
                                sx={{
                                  fontSize: "16px",
                                  fontWeight: 500,
                                }}
                              >
                                N/A
                              </Typography>
                            </Box>
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                              <CardMedia
                                component="img"
                                height="25px"
                                image={registeredBT}
                                alt="Icon"
                                sx={{
                                  objectFit: "contain",
                                  marginRight: "15px",
                                  width: "20px",
                                }}
                              />
                              <Typography
                                sx={{
                                  fontSize: "16px",
                                  fontWeight: 500,
                                }}
                              >
                                N/A
                              </Typography>
                            </Box>{" "}
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                              <CardMedia
                                component="img"
                                height="25px"
                                image={noBT}
                                alt="Icon"
                                sx={{
                                  objectFit: "contain",
                                  marginRight: "15px",
                                  width: "20px",
                                }}
                              />
                              <Typography
                                sx={{
                                  fontSize: "16px",
                                  fontWeight: 500,
                                }}
                              >
                                N/A
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  )}
                  {/* Account Information*/}
                  {loading ? (
                    <Skeleton
                      variant="rectangular"
                      sx={{
                        height: "190px",
                        backgroundColor: "#FFFFFF",
                        border: "solid 1px #E0E0E0",
                        borderRadius: "8px",
                      }}
                      animation="wave"
                    />
                  ) : (
                    <Box
                      sx={{
                        height: "100%",
                        backgroundColor: "#FFFFFF",
                        border: "solid 1px #E0E0E0",
                        borderRadius: "8px",
                        padding: "14px 25px",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        gap: "10px",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                          width: "100%",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "baseline",
                            width: "100%",
                          }}
                        >
                          <Typography
                            sx={{
                              color: "#797D8C",
                              fontSize: "12px",
                              fontWeight: 500,
                            }}
                          >
                            Account Information:
                          </Typography>
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          display: "grid",
                          gridTemplateColumns: "repeat(2, 1fr)",
                          width: "100%",
                          gap: "10px",
                        }}
                      >
                        <Box>
                          <Typography
                            sx={{
                              color: "#797D8C",
                              fontSize: "12px",
                              fontWeight: 500,
                              textAlign: "left",
                            }}
                          >
                            KYC:
                          </Typography>
                          <Box
                            sx={{
                              display: "grid",
                              gridTemplateRows: "1fr 1fr 1fr",
                              gridGap: "5px",
                              justifyContent: "space-between",
                              width: "100%",
                            }}
                          >
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                              <CardMedia
                                component="img"
                                height="25px"
                                image={activeKYC}
                                alt="Icon"
                                sx={{
                                  objectFit: "contain",
                                  marginRight: "15px",
                                  width: "20px",
                                }}
                              />
                              <Typography
                                sx={{
                                  fontSize: "16px",
                                  fontWeight: 500,
                                }}
                              >
                                {MerchantAnalysticsData.totalKYCVerified
                                  ? MerchantAnalysticsData.totalKYCVerified
                                  : "N/A"}
                              </Typography>
                            </Box>{" "}
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                              <CardMedia
                                component="img"
                                height="25px"
                                image={inactiveKYC}
                                alt="Icon"
                                sx={{
                                  objectFit: "contain",
                                  marginRight: "15px",
                                  width: "20px",
                                }}
                              />
                              <Typography
                                sx={{
                                  fontSize: "16px",
                                  fontWeight: 500,
                                }}
                              >
                                {MerchantAnalysticsData.totalKYCUnverified
                                  ? MerchantAnalysticsData.totalKYCUnverified
                                  : "N/A"}
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                        <Box>
                          <Typography
                            sx={{
                              color: "#797D8C",
                              fontSize: "12px",
                              fontWeight: 500,
                              textAlign: "left",
                            }}
                          >
                            Status:
                          </Typography>
                          <Box
                            sx={{
                              display: "grid",
                              gridTemplateRows: "1fr 1fr 1fr",
                              gridGap: "5px",
                              justifyContent: "space-between",
                              width: "100%",
                            }}
                          >
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                              <CardMedia
                                component="img"
                                height="25px"
                                image={activeStatus}
                                alt="Icon"
                                sx={{
                                  objectFit: "contain",
                                  marginRight: "15px",
                                  width: "20px",
                                }}
                              />
                              <Typography
                                sx={{
                                  fontSize: "16px",
                                  fontWeight: 500,
                                }}
                              >
                                {MerchantAnalysticsData.totalActiveAccounts
                                  ? MerchantAnalysticsData.totalActiveAccounts
                                  : "N/A"}
                              </Typography>
                            </Box>
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                              <CardMedia
                                component="img"
                                height="25px"
                                image={inactiveStatus}
                                alt="Icon"
                                sx={{
                                  objectFit: "contain",
                                  marginRight: "15px",
                                  width: "20px",
                                }}
                              />
                              <Typography
                                sx={{
                                  fontSize: "16px",
                                  fontWeight: 500,
                                }}
                              >
                                {MerchantAnalysticsData.totalInactiveAccounts
                                  ? MerchantAnalysticsData.totalInactiveAccounts
                                  : "N/A"}
                              </Typography>
                            </Box>{" "}
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                              <CardMedia
                                component="img"
                                height="25px"
                                image={suspendedStatus}
                                alt="Icon"
                                sx={{
                                  objectFit: "contain",
                                  marginRight: "15px",
                                  width: "20px",
                                }}
                              />
                              <Typography
                                sx={{
                                  fontSize: "16px",
                                  fontWeight: 500,
                                }}
                              >
                                {MerchantAnalysticsData.totalSuspendedAccounts
                                  ? MerchantAnalysticsData.totalSuspendedAccounts
                                  : "N/A"}
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                        <Box>
                          <Typography
                            sx={{
                              color: "#797D8C",
                              fontSize: "12px",
                              fontWeight: 500,
                              textAlign: "left",
                            }}
                          >
                            Verified Email:
                          </Typography>
                          <Box
                            sx={{
                              display: "grid",
                              gridTemplateRows: "1fr 1fr",
                              gridGap: "5px",
                              justifyContent: "space-between",
                              width: "100%",
                            }}
                          >
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                              <CardMedia
                                component="img"
                                height="25px"
                                image={verifiedEmail}
                                alt="Icon"
                                sx={{
                                  objectFit: "contain",
                                  marginRight: "15px",
                                  width: "20px",
                                }}
                              />
                              <Typography
                                sx={{
                                  fontSize: "16px",
                                  fontWeight: 500,
                                }}
                              >
                                {MerchantAnalysticsData.totalVerifiedEmails
                                  ? MerchantAnalysticsData.totalVerifiedEmails
                                  : "N/A"}
                              </Typography>
                            </Box>{" "}
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                              <CardMedia
                                component="img"
                                height="25px"
                                image={unverifiedEmail}
                                alt="Icon"
                                sx={{
                                  objectFit: "contain",
                                  marginRight: "15px",
                                  width: "20px",
                                }}
                              />
                              <Typography
                                sx={{
                                  fontSize: "16px",
                                  fontWeight: 500,
                                }}
                              >
                                {MerchantAnalysticsData.totalUnverifiedEmails
                                  ? MerchantAnalysticsData.totalUnverifiedEmails
                                  : "N/A"}
                              </Typography>
                            </Box>
                          </Box>
                        </Box>

                        <Box>
                          <Typography
                            sx={{
                              color: "#797D8C",
                              fontSize: "12px",
                              fontWeight: 500,
                              textAlign: "left",
                            }}
                          >
                            Is Using 2FA:
                          </Typography>
                          <Box
                            sx={{
                              display: "grid",
                              gridTemplateRows: "1fr 1fr",
                              gridGap: "5px",
                              justifyContent: "space-between",
                              width: "100%",
                            }}
                          >
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                              <CardMedia
                                component="img"
                                height="20px"
                                image={twoFALock}
                                alt="Icon"
                                sx={{
                                  objectFit: "contain",
                                  marginRight: "15px",
                                  width: "20px",
                                }}
                              />
                              <Typography
                                sx={{
                                  fontSize: "16px",
                                  fontWeight: 500,
                                }}
                              >
                                {MerchantAnalysticsData.numberOfAccountWith2FA
                                  ? MerchantAnalysticsData.numberOfAccountWith2FA
                                  : "N/A"}
                              </Typography>
                            </Box>{" "}
                            <Box sx={{ display: "flex", alignItems: "center", position: 'relative' }}>
                              <Box sx={{ position: 'absolute', width: '25px', height: '2px', background: 'red',  transform: 'rotate(45deg)',left: '-2px' }}></Box>
                              <CardMedia
                                component="img"
                                height="20px"
                                image={twoFALock}
                                alt="Icon"
                                sx={{
                                  objectFit: "contain",
                                  marginRight: "15px",
                                  width: "20px",
                                }}
                              />
                              <Typography
                                sx={{
                                  fontSize: "16px",
                                  fontWeight: 500,
                                }}
                              >
                                {MerchantAnalysticsData.numberOfAccountWithout2FA
                                  ? MerchantAnalysticsData.numberOfAccountWithout2FA
                                  : "N/A"}
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  )}
                </Box>
                <MerchantOverviewTable
                  data={MerchantAnalysticsData.accounts}
                  value={value}
                />
              </Box>
            )} 
          </Box>
        </TabPanel>
        <Box
          sx={{
            backgroundColor: "#FFFFFF",
            border: "solid 1px #E0E0E0",
            borderRadius: "8px",
            marginTop: "1rem",
          }}
        ></Box>
      </Box>
    </Box>
  );
};

export default Index;
