import "./App.css";
import React, { useState, useEffect } from "react";
import IndexRoutes from "../routes/index.routes";
import { QueryClient, QueryClientProvider } from "react-query";
import { SignOut } from "../api/Data";
import { useHistory } from "react-router";
import { store } from "../state/store";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import InactivityTracker from "../services/InactivityTimer";

const queryClient = new QueryClient();

function App() {
  // const history = useHistory();
  // const [inactivityTimer, setInactivityTimer] = useState(null);

  // useEffect(() => {
  //   // Start the inactivity timer on component mount
  //   resetInactivityTimer();

  //   // Add event listeners for user activity
  //   window.addEventListener("mousemove", resetInactivityTimer);
  //   window.addEventListener("keydown", resetInactivityTimer);

  //   // Clean up event listeners on component unmount
  //   return () => {
  //     window.removeEventListener("mousemove", resetInactivityTimer);
  //     window.removeEventListener("keydown", resetInactivityTimer);
  //   };
  // }, []);

  // // Function to reset the inactivity timer
  // const resetInactivityTimer = () => {
  //   // Clear the previous timer if it exists
  //   if (inactivityTimer) {
  //     clearTimeout(inactivityTimer);
  //   }

  //   // Set a new timer for 30 seconds of inactivity
  //   const newTimer = setTimeout(handleSignOut, 200000); // 30 seconds in milliseconds
  //   setInactivityTimer(newTimer);
  // };

  // // Function to handle the sign-out after inactivity
  // const handleSignOut = async () => {
  //   // Implement your sign-out logic here
  //   console.log("User has been signed out due to inactivity.");
  //   history.push("/");
  //   localStorage.removeItem("token");
  //   try {
  //     // Call the SignOut function to log the user out
  //     await SignOut();
  //     history.push("/");
  //     localStorage.removeItem("token");
  //   } catch (error) {
  //     console.log("Logout failed:", error.message);
  //   }
  // };
  return (
    <Provider store={store}>
      <div className="App">
        <QueryClientProvider client={queryClient}>
          <IndexRoutes />
          {/* <InactivityTracker timeoutInSeconds={50} /> */}
          <ToastContainer />
        </QueryClientProvider>
      </div>
    </Provider>
  );
}

export default App;
