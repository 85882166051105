import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  CardMedia,
  Skeleton,
  Button,
  Paper,
  InputBase,
  CircularProgress,
} from "@mui/material";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { GetCustomerInformation } from "../../../../state/feautures/customer/getCustomerInformationSlice";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { useAppDispatch, useAppSelector } from "../../../../state/hooks";
import { GetMerchantAnalystics } from "../../../../state/feautures/user/getMerchantAnalysticsSlice";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import UploadIcon from '@mui/icons-material/Upload';
import PrintIcon from '@mui/icons-material/Print';
import { GetBulkPaymentAnalystics } from "../../../../state/feautures/finance/getBulkPaymentAnalysticsSlice";


// Tab Functions
function TabPanel(props: any) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
interface Credentials {
  startDate: string;
  endDate: string;
}

const MerchantPaymentOverview = () => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState<number>(0);
  const [customDate, setCustomDate] = useState(false);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [businessName, setBusinessName] = useState("");
  // const [businessName, setBusinessName] = useState("");
  const [customerDataList, setCustomerDataList] = useState<any[]>([]);
  const [businessNames, setBusinessNames] = useState<string[]>([]);
  const [clientIds, setClientIds] = useState<{ name: string; operator: string }[]>([]); // Updated type
  const [clientNames, setClientNames] = useState<string[]>([]); // Array of names as strings

  const formatDate = (date: Date | null) => {
    return date ? dayjs(date).format("YYYY-MM-DD") : "";
  };  const CustomerInformationData: any = useAppSelector(
    (state) => state.customerInformation
  );

  const BulkPaymentAnalystics = useAppSelector((state) => state.bulkpaymentanalystics);
console.log("BulkPaymentAnalystics",BulkPaymentAnalystics)

  const CustomerInformationloading = CustomerInformationData.loading;
  useEffect(() => {
    if (CustomerInformationData.data !== null) {
      setBusinessName(CustomerInformationData.data.datas.businessName);
      const newBusinessName = CustomerInformationData.data.datas.businessName;
      const newClientIds = CustomerInformationData.data.datas.clientId; // clientId is an array of objects

      if (newBusinessName && !businessNames.includes(newBusinessName)) {
        setBusinessNames(prevNames => [...prevNames, newBusinessName]);
      }
      // Append the entire customer data object to the customerDataList if it's not already there
      if (!customerDataList.some(data => data.datas.businessName === newBusinessName)) {
        setCustomerDataList(prevDataList => [...prevDataList, CustomerInformationData.data]);
      }
 // Add all clientId objects (name and operator) to the array if not already added
 if (newClientIds) {
  setClientIds((prevClientIds) => [
    ...prevClientIds,
    ...newClientIds.filter(
      (id: { name: string; operator: string }) =>
        !prevClientIds.some(
          (prevId) => prevId.name === id.name && prevId.operator === id.operator
        )
    ),
  ]);
  // Extract all client names and store them as strings
  const clientNamesArray = newClientIds.map(
    (id: { name: string; operator: string }) => id.name
  );

  setClientNames((prevNames) => [
    ...prevNames,
    ...clientNamesArray.filter((name: string) => !prevNames.includes(name)), // Avoid duplicates
  ]);
}
    }
  }, [CustomerInformationData, businessNames, customerDataList]);

  console.log("businessNames",clientNames)
  const handleSearchQuery = (e: any) => {
    setSearchQuery(e.target.value);
  };
  const handleGetCustomerInformation = () => {
    const credential = {
      email: searchQuery,
    };
    dispatch(GetCustomerInformation(credential));
  };
  const handleStartDateChange = (date: Date | null) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date: Date | null) => {
    setEndDate(date);
  };

  const handleChange = (event: any, newValue: any) => {
    setValue(newValue);
  };   
  
  const getDateRangeForValue = (
      value: number
    ): { start: string; end: string } => {
      const today = new Date();
      const formatDate = (date: Date): string => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const day = String(date.getDate()).padStart(2, "0");
        return `${year}-${month}-${day}`;
      };

      // Initialize startDate and endDate
      let startDate: Date;
      let endDate: Date;

      switch (value) {
        case 0:
          startDate = today;
          endDate = today;
          break;
        case 1:
          const dayOfWeek = today.getDay();
          const startOfWeek = 0; // Sunday
          const endOfWeek = 6; // Saturday

          startDate = new Date(today);
          startDate.setDate(today.getDate() - dayOfWeek + startOfWeek);

          endDate = new Date(today);
          endDate.setDate(today.getDate() + (endOfWeek - dayOfWeek));
          break;
        case 2:
          startDate = new Date(today.getFullYear(), today.getMonth(), 1);
          endDate = new Date(today.getFullYear(), today.getMonth() + 1, 0);
          break;
        case 3:
          const quarter = Math.floor(today.getMonth() / 3);
          switch (quarter) {
            case 0:
              startDate = new Date(today.getFullYear(), 0, 1); // Q1 start
              endDate = new Date(today.getFullYear(), 2, 31); // Q1 end
              break;
            case 1:
              startDate = new Date(today.getFullYear(), 3, 1); // Q2 start
              endDate = new Date(today.getFullYear(), 5, 30); // Q2 end
              break;
            case 2:
              startDate = new Date(today.getFullYear(), 6, 1); // Q3 start
              endDate = new Date(today.getFullYear(), 8, 30); // Q3 end
              break;
            case 3:
              startDate = new Date(today.getFullYear(), 9, 1); // Q4 start
              endDate = new Date(today.getFullYear(), 11, 31); // Q4 end
              break;
            default:
              throw new Error("Invalid Quartar");
          }
          break;
        case 4:
          startDate = new Date("1970-01-01");
          endDate = today;
          break;
        default:
          startDate = new Date("");
          endDate = new Date("");
      }
      return {
        start: formatDate(startDate),
        end: formatDate(endDate),
      };
    };
    
    const dateRange = getDateRangeForValue(value);
  useEffect(() => {
    // if (MerchantAnalysticsCacheStatus === "idle") {  
    const requestCredentials: Credentials = {
      startDate: dateRange.start,
      endDate: dateRange.end,
    };
    if (value !== 5) {
      dispatch(GetMerchantAnalystics(requestCredentials));
    }
  }, [dispatch, value,dateRange]);

  const handleCustomDate = () => {
    setCustomDate(!customDate);
    const requestCredentials: Credentials = {
      startDate: formatDate(startDate),
      endDate: formatDate(endDate),
    };
    dispatch(GetMerchantAnalystics(requestCredentials));
  };
console.log("startDate",startDate)
  const GenerateReport =()=>{
    const credential = {
      clientIds: clientNames,
      startDate: dateRange.start,
      endDate: dateRange.end,
    };
    dispatch(GetBulkPaymentAnalystics(credential));
  }

  return (
    <Box sx={{ width: "100%" }}>
      {/* Heading */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "start",
          alignItems: "start",
          marginBottom: "20px",
        }}
      >
        <Typography
          sx={{ fontSize: "24px", color: "#45464E", marginBottom: "-5px" }}
        >
          Merchants Payment
        </Typography>
        <Typography
          sx={{
            fontSize: "16px",
            color: "#B2B2B2",
            fontWeight: 500,
            marginTop: "0",
          }}
        >
          Manage your payments
        </Typography>
      </Box>
      <Typography
        sx={{
          fontSize: "16px",
          color: "#45464E",
          fontWeight: 700,
          marginTop: "0",
          textAlign: "left",
        }}
      >
        {value === 0
          ? "Today"
          : value === 1
          ? "This Week"
          : value === 2
          ? "This Month"
          : value === 3
          ? "This Quarter"
          : value === 4
          ? "All Time"
          : "Custom"}
      </Typography>
      {/* Tabs Head*/}
      <Box sx={{ borderBottom: 0, marginBottom: "15px", marginTop: "15px" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          sx={{
            borderBottom: "none", // Remove the default bottom border
          }}
        >
          <Tab
            label="Today"
            className={value === 0 ? "activeTab" : ""}
            sx={{
              border: value === 0 ? "#F59E0B 1px solid" : "#EFF1F6  1px solid",
              borderRadius: "8px",
              fontSize: "14px",
              lineHeight: "16px",
              textTransform: "capitalize",
              marginRight: "12px",
              backgroundColor: value === 0 ? "#FCDFAE" : "inherit",
            }}
            {...a11yProps(0)}
          />
          <Tab
            label="Yesterday"
            className={value === 1 ? "activeTab" : ""}
            sx={{
              border: value === 1 ? "#F59E0B 1px solid" : "#EFF1F6  1px solid",
              borderRadius: "8px",
              fontSize: "14px",
              lineHeight: "16px",
              textTransform: "capitalize",
              marginRight: "12px",
              backgroundColor: value === 1 ? "#FCDFAE" : "inherit",
            }}
            {...a11yProps(1)}
          />
          <Tab
            label="This Week"
            className={value === 2 ? "activeTab" : ""}
            sx={{
              border: value === 2 ? "#F59E0B 1px solid" : "#EFF1F6  1px solid",
              borderRadius: "8px",
              fontSize: "14px",
              lineHeight: "16px",
              textTransform: "capitalize",
              marginRight: "12px",
              backgroundColor: value === 2 ? "#FCDFAE" : "inherit",
            }}
            {...a11yProps(2)}
          />
          <Tab
            label="This Month"
            className={value === 3 ? "activeTab" : ""}
            sx={{
              border: value === 3 ? "#F59E0B 1px solid" : "#EFF1F6  1px solid",
              borderRadius: "8px",
              fontSize: "14px",
              lineHeight: "16px",
              textTransform: "capitalize",
              marginRight: "12px",
              backgroundColor: value === 3 ? "#FCDFAE" : "inherit",
            }}
            {...a11yProps(3)}
          />
          <Tab
            label="This Quarter"
            className={value === 4 ? "activeTab" : ""}
            sx={{
              border: value === 4 ? "#F59E0B 1px solid" : "#EFF1F6  1px solid",
              borderRadius: "8px",
              fontSize: "14px",
              lineHeight: "16px",
              textTransform: "capitalize",
              marginRight: "12px",
              backgroundColor: value === 4 ? "#FCDFAE" : "inherit",
            }}
            {...a11yProps(4)}
          />
          <Tab
            label="All Time"
            className={value === 5 ? "activeTab" : ""}
            sx={{
              border: value === 5 ? "#F59E0B 1px solid" : "#EFF1F6  1px solid",
              borderRadius: "8px",
              fontSize: "14px",
              lineHeight: "16px",
              textTransform: "capitalize",
              marginRight: "12px",
              backgroundColor: value === 5 ? "#FCDFAE" : "inherit",
            }}
            {...a11yProps(5)}
          />
          <Tab
            label="Custom Date"
            className={value === 65 ? "activeTab" : ""}
            // onClick={handleCustomDate}
            sx={{
              border: value === 6 ? "#F59E0B 1px solid" : "#EFF1F6  1px solid",
              borderRadius: "8px",
              fontSize: "14px",
              lineHeight: "16px",
              textTransform: "capitalize",
              marginRight: "12px",
              backgroundColor: value === 6 ? "#FCDFAE" : "inherit",
            }}
            {...a11yProps(6)}
          />
        </Tabs>
      </Box>

      {/* Tabs Content */}
      <Box sx={{ width: "100%" }} aria-label="scrollable auto tabs example">
        {/* All Account List */}
        <TabPanel value={value} index={0}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "25px",
              justifyContent: "flex-start",
              padding: "30px",
              margin: "30px auto ",
              alignItems: "flex-start",
              boxShadow: 1,
              borderRadius: "8px",
            }}
          >
            <Typography
              sx={{ fontSize: "16px", fontWeight: 600, marginBottom: 0 }}
            >
              Add Merchant
            </Typography>
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "repeat(2, 1fr)",
                gap: "10px",
                width: "100%",
              }}
            >
              {" "}
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "repeat(1, 1fr)",
                  gap: "20px",
                  width: "100%",
                }}
              >
                <Box
                  sx={{ display: "flex", flexDirection: "column", gap: "10px" }}
                >
                  <Typography sx={{ fontSize: "12px", textAlign: "left" }}>
                    Add by Merchant Email
                  </Typography>
                  <Paper
                    component="form"
                    sx={{
                      p: "0px 4px",
                      display: "flex",
                      alignItems: "center",
                      width: 400,
                    }}
                  >
                    <InputBase
                      sx={{ ml: 1, flex: 1 }}
                      placeholder="Merchant email"
                      onChange={handleSearchQuery}
                      value={searchQuery}
                      inputProps={{ "aria-label": "search for merchant" }}
                    />
                    <IconButton
                      type="button"
                      sx={{ p: "10px" }}
                      aria-label="search"
                      onClick={handleGetCustomerInformation}
                    >
                      <SearchIcon />
                    </IconButton>
                  </Paper>
                </Box>
                <Box
                  sx={{ display: "flex", flexDirection: "column", gap: "10px" }}
                >
                  <Typography sx={{ fontSize: "12px", textAlign: "left" }}>
                    Add by Account Created
                  </Typography>
                  <Paper
                    component="form"
                    sx={{
                      p: "0px 4px",
                      display: "flex",
                      alignItems: "center",
                      width: 400,
                    }}
                  >
                    <InputBase
                      sx={{ ml: 1, flex: 1 }}
                      placeholder="This Month"
                      onChange={handleSearchQuery}
                      value={searchQuery}
                      inputProps={{ "aria-label": "search for merchant" }}
                    />
                    <IconButton
                      type="button"
                      sx={{ p: "10px" }}
                      aria-label="KeyboardArrowDownIcon"
                      onClick={handleGetCustomerInformation}
                    >
                      <KeyboardArrowDownIcon />
                    </IconButton>
                  </Paper>
                </Box>
                <Box
                  sx={{ display: "flex", flexDirection: "column", gap: "10px" }}
                >
                  <Typography sx={{ fontSize: "12px", textAlign: "left" }}>
                    Add by Merchant List
                  </Typography>
                  <Paper
                    component="form"
                    sx={{
                      p: "0px 4px",
                      display: "flex",
                      alignItems: "center",
                      width: 400,
                    }}
                  >
                    <InputBase
                      sx={{ ml: 1, flex: 1 }}
                      placeholder="Drag or drop your file for upload"
                      onChange={handleSearchQuery}
                      value={searchQuery}
                      inputProps={{ "aria-label": "search for merchant" }}
                    />
                    <IconButton
                      type="button"
                      sx={{ p: "10px" }}
                      aria-label="UploadIcon"
                      onClick={handleGetCustomerInformation}
                    >
                      <UploadIcon />
                    </IconButton>
                  </Paper>
                </Box>
              </Box>{" "}
               {/* List of Business Names */}
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: 'repeat(1, 1fr)',
          gap: '10px',
          width: '100%',
          marginTop: '25px',
        }}
      >
        {businessNames.map((name, index) => (
          <Paper
            key={index}
            sx={{
              p: '2px 4px',
              display: 'flex',
              alignItems: 'center',
              justifySelf: 'flex-start',
              width: 400,
              height: 45,
            }}
          >
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              value={name === '' ? '' : name === null ? 'N/A' : name}
              inputProps={{ 'aria-label': 'business name display' }}
              readOnly
            />
            <IconButton
              type="button"
              sx={{ p: '10px', color: 'red' }}
              aria-label="clear"
              onClick={() => {
                setBusinessNames((prevBusinessNames) =>
                  prevBusinessNames.filter((_, i) => i !== index)
                );
                setCustomerDataList((prevCustomerDataList) =>
                  prevCustomerDataList.filter((_, i) => i !== index)
                );
              }}
            >
              <CancelOutlinedIcon />
            </IconButton>
          </Paper>
        ))}
      </Box>
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "repeat(1, 1fr)",
                  gap: "50px",
                  width: "100%",
                  marginTop: "25px",
                }}
              >
                <Paper
                  component="form"
                  sx={{
                    p: "2px 4px",
                    display: "flex",
                    alignItems: "center",
                    justifySelf: "flex-start",
                    width: 400,
                    height: 45,
                  }}
                >
                  <InputBase
                    sx={{ ml: 1, flex: 1 }}
                    placeholder="Business Name"
                    value={
                      businessName === ""
                        ? ""
                        : businessName === null
                        ? "N/A"
                        : businessName
                    }
                    inputProps={{ "aria-label": "search google maps" }}
                  />
                  <IconButton
                    type="button"
                    sx={{ p: "10px", color: "red" }}
                    aria-label="search"
                    onClick={() => setBusinessName("")}
                  >
                    <CancelOutlinedIcon />
                  </IconButton>
                </Paper>
              </Box>
            </Box>
            {/* Buttons */}
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                margin: "auto",
                width: "80%",
                gap: "30px",
              }}
            >
              <Button
                sx={{
                  height: "55px",
                  backgroundColor: "#F59E0B",
                  "&:hover": {
                    backgroundColor: "#E18D00", // Change this to your desired hover color
                  },
                }}
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                // onClick={handleMerchantFinanceReport}
                // disabled={!mOperator || !startDate || !endDate}
              >
                {loading ? (
                  <CircularProgress sx={{ width: "10px", color: "white" }} />
                ) : (
                  <Typography
                    sx={{ display: "flex" }}
                    onClick={GenerateReport}
                  >
                    Generate Report
                  </Typography>
                )}
              </Button>

              <Button
                sx={{
                  height: "55px",
                  backgroundColor: "#FFF",
                  border: "2px solid #F59E0B",
                  color: "#F59E0B",
                  "&:hover": {
                    border: "2px solid #F59E0B",
                  },
                }}
                type="submit"
                variant="outlined"
                color="primary"
                fullWidth
                //   disabled={!mDocumentType}
              >
                Reset
              </Button>
            </Box>
          </Box>
          <Box
                sx={{
                  background: "#FFFFFF",
                  padding: "14px 25px",
                  display: "flex",
                  flexDirection: "column",
                  gap: "30px",
                }}
              >
                <Box sx={{display: 'flex', justifyContent: 'space-between',alignItems: 'center'}}>
                <Typography
                  sx={{ fontSize: "14px", color: "#797D8C", textAlign: "left" }}
                >
                  Payments by transaction type:
                </Typography>
                <Box sx={{cursor: 'pointer',display: 'flex', alignItems: 'center', background: '#F59E0B', width: '130px',borderRadius: '8px', justifyContent: 'center' }}>
                <IconButton
                      type="button"
                      sx={{ p: "10px", color: 'white' }}
                      aria-label="PrintIcon"
                    //   onClick={handleGetCustomerInformation}
                    >
                      <PrintIcon />
                    </IconButton><Typography
                  sx={{ fontSize: "14px", color: "white" }}
                >
                 Print
                </Typography></Box>
                
                </Box>
                  <Box
                    sx={{
                      display: "grid",
                      gridTemplateColumns: "0.5fr 1fr 1fr 0.5fr",
                      gap: "30px",
                      textAlign: "left",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "12px",
                      }}
                    >
                      <Typography sx={{ fontSize: "16px", color: "#FFF" }}>
                        .
                      </Typography>
                      <Typography sx={{ fontSize: "16px", color: "#FFF" }}>
                        .
                      </Typography>
                      <Typography sx={{ fontSize: "16px", color: "#56616B" }}>
                        Collection
                      </Typography>
                      <Typography sx={{ fontSize: "16px", color: "#56616B" }}>
                        Disbursement
                      </Typography>
                      <Typography sx={{ fontSize: "16px", color: "#56616B" }}>
                        Refund
                      </Typography>
                      <Typography sx={{ fontSize: "16px", color: "#56616B" }}>
                        Payout
                      </Typography>
                      <Typography sx={{ fontSize: "16px", color: "#56616B" }}>
                        Transfer
                      </Typography>
                      <Typography sx={{ fontSize: "16px", color: "#56616B" }}>
                        Top up
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "16px",
                          color: "#56616B",
                          fontWeight: "700",
                        }}
                      >
                        All
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "20px",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#797D8C",
                          border: "1px solid #1FAF38",
                          background: "rgba(31, 175, 56, 0.1)",
                          height: "20px",
                          borderRadius: "8px",
                          fontSize: "14px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        Succesful
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          gap: "15px",
                          justifyContent: "space-between",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "12px",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              gap: "15px",
                            }}
                          >
                            {" "}
                            <Typography
                              sx={{ fontSize: "14px", color: "#797D8C" }}
                            >
                              Count
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              gap: "15px",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                gap: "15px",
                              }}
                            >
                            <Typography
                              sx={{
                                fontSize: "14px",
                                color: "#797D8C",
                                fontWeight: "400",
                              }}
                            >Data</Typography>
                            </Box>
                            <Typography
                              sx={{
                                fontSize: "14px",
                                color: "#797D8C",
                                fontWeight: "700",
                              }}
                            ></Typography>
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "12px",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              gap: "15px",
                            }}
                          >
                            {" "}
                            <Typography
                              sx={{ fontSize: "14px", color: "#797D8C" }}
                            >
                              Value
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              gap: "15px",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                gap: "15px",
                              }}
                            >
                                <Typography
                              sx={{
                                fontSize: "14px",
                                color: "#797D8C",
                                fontWeight: "400",
                              }}
                            >Data</Typography>
                            </Box>
                            <Typography
                              sx={{
                                fontSize: "14px",
                                color: "#797D8C",
                                fontWeight: "700",
                              }}
                            ></Typography>
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "12px",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              gap: "15px",
                            }}
                          >
                            <Typography
                              sx={{ fontSize: "14px", color: "#797D8C" }}
                            >
                              %
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              gap: "15px",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                gap: "15px",
                              }}
                            >
                                <Typography
                              sx={{
                                fontSize: "14px",
                                color: "#797D8C",
                                fontWeight: "400",
                              }}
                            >Data</Typography>
                            </Box>
                            <Typography
                              sx={{
                                fontSize: "14px",
                                color: "#797D8C",
                                fontWeight: "700",
                              }}
                            ></Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "20px",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#797D8C",
                          border: "1px solid #FF5403",
                          background: "rgba(255, 84, 3, 0.1)",
                          height: "20px",
                          borderRadius: "8px",
                          fontSize: "14px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        Failed
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          gap: "15px",
                          justifyContent: "space-between",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "12px",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              gap: "15px",
                            }}
                          >
                            {" "}
                            <Typography
                              sx={{ fontSize: "14px", color: "#797D8C" }}
                            >
                              Count
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              gap: "15px",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                gap: "15px",
                              }}
                            >
                                <Typography
                              sx={{
                                fontSize: "14px",
                                color: "#797D8C",
                                fontWeight: "400",
                              }}
                            >Data</Typography>
                            </Box>
                            <Typography
                              sx={{
                                fontSize: "14px",
                                color: "#797D8C",
                                fontWeight: "700",
                              }}
                            ></Typography>
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "12px",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              gap: "15px",
                            }}
                          >
                            {" "}
                            <Typography
                              sx={{ fontSize: "14px", color: "#797D8C" }}
                            >
                              Value
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              gap: "15px",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                gap: "15px",
                              }}
                            >
                                <Typography
                              sx={{
                                fontSize: "14px",
                                color: "#797D8C",
                                fontWeight: "400",
                              }}
                            >Data</Typography>
                            </Box>
                            <Typography
                              sx={{
                                fontSize: "14px",
                                color: "#797D8C",
                                fontWeight: "700",
                              }}
                            ></Typography>
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "12px",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              gap: "15px",
                            }}
                          >
                            <Typography
                              sx={{ fontSize: "14px", color: "#797D8C" }}
                            >
                              %
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              gap: "15px",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                gap: "15px",
                              }}
                            >
                                <Typography
                              sx={{
                                fontSize: "14px",
                                color: "#797D8C",
                                fontWeight: "400",
                              }}
                            >Data</Typography>
                            </Box>
                            <Typography
                              sx={{
                                fontSize: "14px",
                                color: "#797D8C",
                                fontWeight: "700",
                              }}
                            ></Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                  </Box>
        </TabPanel>

        <TabPanel value={value} index={5}>
          {/* CUSTOM DATE */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              backgroundColor: "#FFFFFF",
              border: "solid 1px #E0E0E0",
              borderRadius: "8px",
              padding: "14px 25px",
              marginBottom: "20px",
            }}
          >
            <Typography
              sx={{
                color: "#797D8C",
                fontSize: "12px",
                fontWeight: 500,
                textAlign: "left",
              }}
            >
              Choose Custom Date:
            </Typography>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "row",
                gap: "50px",
              }}
            >
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    position: "relative",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <DemoContainer components={["DatePicker"]}>
                      <DatePicker
                        label="Start Date"
                        value={startDate}
                        onChange={handleStartDateChange}
                        sx={{ width: "50px" }}
                      />
                    </DemoContainer>
                    <Box sx={{ width: "15px", border: "#000 solid 1px" }} />
                    <DemoContainer components={["DatePicker"]}>
                      <DatePicker
                        label="End Date"
                        value={endDate}
                        onChange={handleEndDateChange}
                        sx={{ width: "50px" }}
                      />
                    </DemoContainer>
                  </Box>
                </Box>
              </LocalizationProvider>
              <Button
                sx={{
                  height: "55px",
                  backgroundColor: "#F59E0B",
                  "&:hover": {
                    backgroundColor: "#E18D00", // Change this to your desired hover color
                  },
                }}
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                onClick={handleCustomDate}
              >
                Search
              </Button>
            </Box>
          </Box>
        </TabPanel>
        <Box
          sx={{
            backgroundColor: "#FFFFFF",
            border: "solid 1px #E0E0E0",
            borderRadius: "8px",
            marginTop: "1rem",
          }}
        ></Box>
      </Box>
    </Box>
  );
};

export default MerchantPaymentOverview;
